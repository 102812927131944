import React from 'react';
import cn from 'classnames';
import { Link} from "react-router-dom";


const PageNotFound = ({ className, text }) =>{
	return( <div className={cn('flex flex-col items-center', className)}>
         
      <div className="w-full h-full min-h-[380px] md:min-h-[450px] flex items-center justify-center relative">
        <img
          src={process.env.REACT_APP_URL+'/404pagenotfound.jpg'}
          alt="Page Not found"
          className="w-full h-full object-contain"
          layout="fill"
        />
      </div>
     
        <h3 className="w-full text-center text-xl font-semibold text-body my-7">
          <Link to="/" className="text-accent hover:text-text-light">GO BACK TO SITE</Link>
        </h3>
      
    </div>);
}

export default PageNotFound;


