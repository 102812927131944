import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {GoogleRecaptchaWrapper} from './GoogleRecaptchWraper';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleRecaptchaWrapper
    reCaptchaKey="6Led2F0lAAAAAEF1-cjehalCzusmcf5GHmr3LWPC"
    scriptProps={{
      async: false, // optional, default to false,
      defer: true, // optional, default to false
      appendTo: 'body', // optional, default to "head", can be "head" or "body",
      
    }}
    >
    <App />
    </GoogleRecaptchaWrapper>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
