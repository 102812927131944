
import {APP_URL, IMG_URL, amount_format,discount} from '../components/config/Common'
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { BiRupee } from "react-icons/bi";
import { motion as ms } from 'framer-motion';
import {Links} from '../routes/PathLink'
import { Link } from "react-router-dom";
const PartsCard = ({
   item,
   upcoming
}:props) => {

   return(
     <div className="bg-light drop-shadow-lg rounded-lg  ">
      <Link to={`${Links.PARTSLOOKUP}/parts/${item?.partchildcat?.slug}/${item.slug_main}`} >

       <div className="px-2 py-2 w-full">
       <div className="flex w-full">
          <div className="justify-center align-center">
              {item.image
                  ?
                  <LazyLoadImage src={`${IMG_URL}parts_images/${item.image}`} placeholderSrc={`${APP_URL}/img/no-image-available.png`} className="h-58 w-full sm:h-56 object-cover" alt={item.title} sizes="10vw" />
                  :
                  <LazyLoadImage src={`${APP_URL}/img/no-image-available.png`} alt={item.title} sizes="10vw" />
               }
            </div>
          </div>
          </div>
            <div className="mb-0  text-black bg-gray-200  border-gray-900 flex flex-col flex-wrap items-center justify-between gap-x-8 text-base font-bold text-heading sm:flex-row lg:flex-nowrap">
            <div className="  w-full px-2 py-2  ">
              <span className="mb-2 block lg:mb-0 lg:inline-block lg:ltr:mr-4 lg:rtl:ml-4">
               {item.title}
              </span>
           </div>
          <div>
        </div>
      </div>
      </Link>
    </div>
   )
}

export default PartsCard;