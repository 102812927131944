import {APP_URL} from './config/Common'
const NoRecords = (props) => {

   return(
      <div class="flex flex-col items-center w-5/12 mx-auto">
         <div class="w-full h-full flex items-center justify-center">
            <img alt="Sorry, No Product Found :(" 
               loading="lazy" 
               decoding="async" 
               data-nimg="1" 
               class="w-full h-full object-contain" src={`${APP_URL}/img/no-result.svg`} 
            />
         </div>
            <h3 class="w-full text-center text-xl font-semibold text-body my-7">
            {props.message}
            </h3>
         </div>
   )
}

export default NoRecords