
import {Links} from '../routes/PathLink'

export const SideMenu = () => {

   let user_type = localStorage.getItem('user_type')
   //console.log('side menu type', user_type)

   if(user_type === 'dealer'){
      //Dealer menu
      const menu = [
         {
            label:'Profile',
            link:Links.PROFILE,
         },
         {
            label:'Products',
            link:Links.MY_PRODUCTS,
         },
         {
            label:'My Wishlist',
            link:Links.MY_WISHLIST,
         },
         {
            label:'My Orders',
            link:Links.ORDERS,
         },
         {
            label:'Assigned AMC',
            link:Links.AMS_TICKETS,
         },
         {
            label:`AMC for bidding`,
            link:Links.AMC_BID,
         },
         {
            label:`My AMC Proposals`,
            link:Links.MY_PROPOSALS,
         },
         {
            label:'My Service Tickets',
            link:Links.CMS_TICKETS,
         },
         {
            label:'Change Password',
            link:Links.CHANGE_PASSWORD,
         },
      ]
      return menu

   }else{
      //users/doctor menu
      const menu = [
         {
            label:'Profile',
            link:Links.PROFILE,
         },
         ,
         {
            label:'Products',
            link:Links.MY_PRODUCTS,
         },
         {
            label:'My Wishlist',
            link:Links.MY_WISHLIST,
         },
         {
            label:'My Orders',
            link:Links.ORDERS,
         },
         {
            label:'My AMC Subscriptions',
            link:Links.AMS_TICKETS,
         },
         {
            label:'CMC Tickets',
            link:Links.CMS_TICKETS,
         },         
         {
            label:'Change Password',
            link:Links.CHANGE_PASSWORD
         },
      ]
      return menu
   }   
}