import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import {CloseIcon} from '../custom-icons'

const ModalView = ({ open, onClose, children }: any) => {

   const cancelButtonRef = useRef(null)

   return(
      <Transition.Root show={open} as={Fragment} dir='ltr'>
      <Dialog 
         as="div" 
         className="relative z-50" 
         initialFocus={cancelButtonRef} 
         open={open} 
         onClose={onClose}
      >
         <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
         >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
         </Transition.Child>
         <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
               <Transition.Child
               as={Fragment}
               enter="ease-out duration-300"
               enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
               enterTo="opacity-100 translate-y-0 sm:scale-100"
               leave="ease-in duration-200"
               leaveFrom="opacity-100 translate-y-0 sm:scale-100"
               leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
               >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                     <button aria-label="Close panel" className="absolute top-4 z-[60] inline-block outline-none focus:outline-none ltr:right-4 rtl:left-4 lg:hidden" onClick={onClose}>
                        <CloseIcon className="h-4 w-4" />
                     </button>
                     {children}
                  </Dialog.Panel>
               </Transition.Child>
            </div>
         </div>
      </Dialog> 
      </Transition.Root>
   )

}

export default ModalView