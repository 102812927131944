import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'


import {CloseIcon} from '../../custom-icons'
import { Link } from "react-router-dom";


export default function Quotebyphone(props) {
  const [open, setOpen] = useState(true)
  
  const [openForm, setOpenForm] = useState('LoginView')
  
  const [socialData, setSocialData] = useState(null)

  const cancelButtonRef = useRef(null)

  const handleView = (view) => {
    setOpenForm(view)
  }
  const closeModal = () => {
    setOpen(false);
    props.closeModel()
  }

 
  return (
   <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
         initialFocus={cancelButtonRef}
        static
        open={open}
        onClose={closeModal}
        dir="ltr"
      >
        <div className="min-h-full text-center md:p-5">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 h-full w-full bg-gray-900 bg-opacity-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="w-full text-start relative inline-block  align-middle  transition-all md:rounded-xl">
              
              <div className="m-auto w-full  rounded-md bg-light p-4 pb-6 sm:w-3/4 md:rounded">
               <div className="h-full w-full">
                 <div className="flex h-full flex-col ">
                  
                    <div className="flex items-center">
                     <div className="w-11/12">
                        <div className="mt-0 text-xl font-bold text-heading"><h3>Quote by Phone</h3></div>
                     </div>
                     <div className=" flex content-end ">
                     <button
                      onClick={closeModal}
                      aria-label="Close panel"
                      ref={cancelButtonRef}
                      className="end-4 absolute top-4 z-[60] inline-block outline-none focus:outline-none "
                    >
                      <span className="sr-only">close</span>
                      <CloseIcon className="h-4 w-4" />
                    </button>
                     </div>
                   </div>

                   <div className="my-3 font-lg ">Phone: <span class="font-oswald font-lg text-accent"><a href="tel:614-642-1030">614-642-1030</a></span></div>
                   <div className="space-s-4 mt-8 flex w-full items-center justify-between">
                     <div className="w-full ">
                       <table class="table-auto border-collapse border border-slate-400 border-spacing-3 ">
                       <thead>
                         <tr className="border border-slate-300 bg-gray-200 py-5">
                           <th colspan="3">Hours</th>
                         </tr>
                       </thead>
                       <tbody>
                         <tr>
                           <td className="border border-slate-300 py-3 px-3">Day </td>
                           <td className="border border-slate-300">Open</td>
                           <td className="border border-slate-300">Close</td>
                         </tr>
                         <tr>
                           <td className="border border-slate-300 py-3 px-3">Monday-Friday (Evening and Saturday by appointment ONLY)   </td>
                           <td className="border border-slate-300">9:00 a.m</td>
                           <td className="border border-slate-300">5:00 p.m.</td>
                         </tr>
                         <tr>
                           <td className="border border-slate-300 py-3 px-3">Saturday</td>
                           <td className="border border-slate-300">10:00 a.m.</td>
                           <td className="border border-slate-300">1:00 p.m.</td>
                         </tr>
                          <tr>
                           <td className="border border-slate-300 py-3 px-3">Sunday</td>
                           <td className="border border-slate-300">Closed</td>
                           <td className="border border-slate-300"></td>
                         </tr>
                       </tbody>
                     </table>
                     </div>

                    
                   </div>
                 </div>
               </div>
             </div>
             </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}