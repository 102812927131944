import {amount_format} from '../components/config/Common'

const SavePrice = ({max, min}:props) => {
   
   max = Number(max)
   min = Number(min)
   
   let def =  max - min
   let disc = (def/max)*100
   disc = Math.round(disc);

   if(def <= 0 || disc <= 0){
      return null
   }else{
      return(
         <div className="text-md leading-7 text-body flex items-center">
            <div className="mr-2">You save:</div>
            {amount_format(def)}
            <div className="ml-2">({disc}%)</div>
         </div>
      )
   }

}

export default SavePrice