import React, { createContext, useContext, useState, useEffect } from "react";
import {API_ENDPOINT} from '../components/config/Common'

const SiteSettingContext = createContext('SettingsContext');

export const SiteSettingProvider = ({children}) => {

   const [sitelogo, setSiteLogo] = useState(0)
   

   useEffect(() => {
      site_settings()
   },[])

   const site_settings = async () => {
   
      const response = await fetch(`${API_ENDPOINT}/get_all_settings`)
      const result = await response.json();
      console.log('Site settings',result.data);
      setSiteLogo(result.data.site_logo);
   }

   return <SiteSettingContext.Provider value={{ sitelogo }} >{children}</SiteSettingContext.Provider>

}

export const useSiteSettings = () => {
   const context = React.useContext(SiteSettingContext);
   if (context === undefined) {
      throw new Error(`useSiteSettings must be used within a SiteSettingContext`);
   }
   return React.useMemo(() => context, [context]);
   //return context;
};