import { Link } from "react-router-dom";
import {APP_URL} from '../components/config/Common'
import { useSiteSettings } from '../site-settings/site-settings';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const Logo = () => {
  const { sitelogo } = useSiteSettings();
  return (
    <Link to="/" class="inline-flex mx-auto lg:mx-0">
      <span className="flex relative overflow-hidden w-44 items-center">
        {sitelogo ?
        ( <LazyLoadImage src={`${process.env.REACT_APP_IMG_URL+sitelogo}`} alt="CTMRI Logo" />)
        :
        ( <LazyLoadImage src={`${APP_URL}/img/logo.png?version=1`} alt="CTMRI Logo" />)
        }
       
        {/* <h1 className="text-3xl font-black text-accent">CTMRI.in</h1> */}
      </span>
    </Link>
  );
};

export default Logo;
