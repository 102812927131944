import React from "react";
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {Links} from './PathLink'

import Home from "../components/Home";
import ProductDetails from "../components/ProductDetails";


import Contact from "../components/static-pages/contact";
import About from "../components/static-pages/About";
import Terms from '../components/static-pages/Terms'
import Finance from '../components/static-pages/Finance'
import Privacy from '../components/static-pages/Privacy'
import Services from '../components/services/services';
import ServiceDetails from '../components/services/serviceDetails';
import Category from '../components/category/Category'

import PartsLookup from '../components/partslookup';
import ModelDetails from '../components/partslookup/modeldetails';
import PartsDetails from '../components/partslookup/partsdetails';
import ShowTestimonials from '../components/testimonial/ShowTestimonials'
import PageNotFound from '../components/PageNotFound';
import SubModelDetails from '../components/partslookup/submodeldetails'
import EquipmentForRent from '../components/equipmentforrent/equipmentforrent';

// basename='/powertripohio/'
//"homepage": "https://himlalpun.net/powertripohio/",
const RoutePath = () => {
    return (        
        <Router basename='/'>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path={Links.CONTACT} element={<Contact />} />
                <Route path={Links.ABOUT} element={<About />} />
                <Route path={Links.TERMS} element={<Terms />} />
                <Route path={Links.PRIVACY} element={<Privacy />} />
                <Route path={Links.FINANCE} element={<Finance />} />
                <Route path={`${Links.PRODUCT}/:slug`} element={<ProductDetails />} />
                <Route path={Links.CATEGORY} element={<Category />} />
                <Route path={`${Links.CATEGORY}/:slug`} element={<Category />} />
                <Route path={`${Links.CATEGORY}/:slug/:sub_slug`} element={<Category />} />
                <Route path={Links.EQUIPMENTFORRENT} element={<EquipmentForRent />} />
                <Route path={`${Links.EQUIPMENTFORRENT}/:slug`} element={<EquipmentForRent />} />
                <Route path={Links.SERVICES} element={<Services />} /> 
                <Route path={`${Links.SERVICES}/:slug`} element={<ServiceDetails />} /> 
                <Route path={Links.PARTSLOOKUP} element={<PartsLookup />} /> 
                <Route path={`${Links.PARTSLOOKUP}/model/:slug`} element={<ModelDetails />} /> 
                <Route path={`${Links.PARTSLOOKUP}/submodel/:slug`} element={<SubModelDetails />} /> 
                <Route path={`${Links.PARTSLOOKUP}/parts/:model_slug/:slug`} element={<PartsDetails />} /> 
                <Route path={Links.TESTIMONIALS} element={<ShowTestimonials />} />
                <Route path="*" element={<PageNotFound text='This page could not be found' />} /> 
            </Routes>
        </Router>
    );
  };
  
  export default RoutePath;
  