import React, {useState, useEffect,useRef , useCallback} from "react";
import SearchBox from './search-box.tsx';
import { 
  useParams, 
  useNavigate, 
  createSearchParams, 
  useLocation, 
} from "react-router-dom";
import {queryStringToJSON} from '../../components/config/Common.js'

import { useSearch } from './search.context.tsx';

interface Props {
  label: string;
  variant?: 'minimal' | 'normal' | 'with-shadow' | 'flat';
  [key: string]: unknown;
}

const Search: React.FC<Props> = ({ label, variant, ...props }) => {

  const { searchTerm, updateSearchTerm } = useSearch();
  //const { value, setserachValue } = useState('');
  const submitRef = useRef();
  const testCallback = useCallback(() => {
    console.log("CALL BACK",searchTerm)
    setTimeout(() => { 
      submitRef.current.click();
    }, 2000);
    //
  }, [searchTerm]);

  const location = useLocation()
  const navigate = useNavigate();

  const handleOnChange = (e: any) => {
    const {value} = e.target;
    updateSearchTerm(value);
  };

  const onSearch = (e: any) => {
    e.preventDefault();
    
    const { pathname, search } = location;
    //if (!searchTerm) return;
    updateSearchTerm(searchTerm)
    /* let query = queryStringToJSON(search)
    if(search){
      query = { ...query, search: searchTerm }
    }else{
      query = {search: searchTerm}
    }
     */
    let path = pathname;
    if(!path.includes('/equipment-for-sale')){
      path = '/equipment-for-sale'
    }
    
    //console.log(query)
    navigate(path);
    /* if (!searchTerm) return;
    const { pathname, query } = router;
    router.push(
      {
        pathname,
        query: { ...query, text: searchTerm },
      },
      undefined,
      {
        scroll: false,
      }
    ); */
  };

  
function onSetValue(serachtext){
  console.log("AAA",serachtext);
  updateSearchTerm(serachtext);
  testCallback();
  //submitRef.current.requestSubmit();
   //
  // submitRef?.current.dispatchEvent(new Event("submit"));

}
  function clearSearch() {
    updateSearchTerm('');
    /* const { pathname, query } = router;
    const { text, ...rest } = query;
    if (text) {
      router.push(
        {
          pathname,
          query: { ...rest },
        },
        undefined,
        {
          scroll: false,
        }
      );
    } */
  }

  return (
    <SearchBox
      label={label}
      onSubmit={onSearch}
      onClearSearch={clearSearch}
      onChange={handleOnChange}
      value={searchTerm}
      name="search"
      placeholder={'Search product'}
      variant={'minimal'}
      onSetValue={onSetValue}
      {...props}
      submitRef={submitRef}

    />
  );
};

export default Search;
