
    import { useState, useEffect } from "react";
    import { useLocation } from "react-router-dom";

    const ScrollPageTop = ({children}) => {
      const location = useLocation();
      const pathname = location.pathname.substring(1);
      
      const [pn, setPn] = useState();

      useEffect(() => {
        /* console.log('location.pathname===', pathname )
        setPn(pathname)
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        }); */
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        }, 0);
      }, []);

      return children || null;
    };

    export default ScrollPageTop;