import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import Input from '../../ui/input.jsx'
import TextArea from '../../ui/text-area.tsx'
import Button from '../../ui/Button.tsx'
import {API_ENDPOINT, APP_URL, APP_NAME, admin_phone, toast_anim} from '../config/Common'

import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {CloseIcon} from '../../custom-icons'
import { Link } from "react-router-dom";

const formSchema = yup.object().shape({
   name: yup.string().required('Name is required'), 
   email: yup.string().required('Email is required'), 
   comment: yup.string().required('Comment is required'), 
})
let defaultValues = {  
   name: '',
   email:'',
   phone_no:'',
   comment:''
};

export default function QuestionRequest(props) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true)
  const  {record} = props;
  console.log("data",record);
  const cancelButtonRef = useRef(null)

  const handleView = (view) => {
    //setOpenForm(view)
  }
  const closeModal = () => {
    setOpen(false);
    props.closeModel()
  }

  const onSubmit = (values) => {

    setLoading(true)
      fetch(`${API_ENDPOINT}/store_questionrequest_form`,{
      method: 'POST',
      headers : {        
        'Content-Type': 'application/json',
        'Accept': 'application/json', 
      },
         body: JSON.stringify(values)
    }).then(function (response) {
        return response.json();
    }).then( (result)=> {
      //console.log('mail======',result)
      
         setLoading(false)
         if(result.status === 1){
            defaultValues = {  
               name: '',
               email:'',
               subject:'',
               description:''
            };
            reset(defaultValues)  
            toast_anim('Request sent')   
            closeModal();
         }
    })
  }

  const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(formSchema),
   });

 
  return (
   <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
         initialFocus={cancelButtonRef}
        static
        open={open}
        onClose={closeModal}
        dir="ltr"
      >
        <div className="min-h-full text-center md:p-5">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 h-full w-full bg-gray-900 bg-opacity-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="w-full text-start relative inline-block  align-middle  transition-all md:rounded-xl">
              
              <div className="m-auto w-full  rounded-md bg-light p-4 pb-6 sm:w-3/4 md:rounded">
               <div className="h-full w-full">
                 <div className="flex h-full flex-col  ">
                  
                    <div className="flex items-center border-gray-100 border-b-2 pb-3">
                     <div className="w-11/12">
                        <div className="mt-0 text-xl font-bold text-heading"><h3>Questions - {record?.product_name}</h3></div>
                     </div>
                     <div className=" flex justify-end   w-1/12  ">
                     <button
                      onClick={closeModal}
                      aria-label="Close panel"
                      ref={cancelButtonRef}
                      className="end-4 absolute top-4 z-[60] inline-block outline-none focus:outline-none "
                    >
                      <span className="sr-only">close</span>
                      <CloseIcon className="h-4 w-4" />
                    </button>
                     </div>
                   </div>
                   <div className="space-s-4 mt-8 flex w-full items-center justify-between">
                     <div className="w-full ">
                       <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                             <Input                
                                label={`Name`}
                                {...register('name')}                     
                                variant="outline"
                                className="mb-5"
                                error={errors.name?.message}
                                required
                             />
                             <Input                
                                label={`Email`}
                                {...register('email')}                     
                                variant="outline"
                                className="mb-5"
                                error={errors.email?.message}
                                type="email"
                                required
                             />
                          </div>
                          <Input                
                             label={`Phone No`}
                             {...register('phone_no')}                     
                             variant="outline"
                             className="mb-5"
                             error={errors.phone_no?.message}
                          />
                          <TextArea
                             label='Comment'
                             {...register('comment')}                        
                             variant="outline"
                             className="mb-5"
                             error={errors.comment?.message}
                             required
                          />
                            <Input                
                             {...register('product_id')}                     
                             type="hidden"
                             value={record?.p_id}
                          />
                            <Input                
                            
                             {...register('product_name')}                     
                            type="hidden"
                             value={record?.product_name}
                          />
                          <Button loading={loading}>
                             Submit
                          </Button>
                          
                       </form>
               
                     </div>

                    
                   </div>
                 </div>
               </div>
             </div>
             </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}