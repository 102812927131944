import React from "react";
import { Link } from "react-router-dom";
import {Bed, Table, Sofa, Chair, Bucket, XrayIcon, MriIcon, CtScanIcon} from "../custom-icons";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import {API_ENDPOINT, APP_URL, APP_NAME, IMG_URL, amount_format,discount, LIMIT,showRating} from './config/Common'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollPageTop from '../routes/ScrollPageTop'

import { BiRupee } from "react-icons/bi";
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import {Links} from '../routes/PathLink'
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { BsFillPlayBtnFill, BsFillPlayFill, BsArrowRight } from "react-icons/bs";

// import Swiper core and required modules
import { Navigation, Pagination, Thumbs, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {ChevronLeft, ChevronRight, Loading} from "../custom-icons";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';

import ProductCard from '../cards/ProductCard'
import VideoSlider from './VideoSlider';

import InfiniteScroll from "react-infinite-scroll-component";
// product gallery breakpoints
const galleryCarouselBreakpoints = {
	320: {
	  slidesPerView: 1,
	},
	480: {
	  slidesPerView: 2,
	},
	640: {
	  slidesPerView: 3,
	},
	
  };

const upcomingBreakpoints = {
	320: {
	  slidesPerView: 1,
	},
	510: {
	  slidesPerView: 1,
	},
	600: {
		slidesPerView: 2,
	},
	950: {
		slidesPerView: 3,
	},
	
};
const testimonials = [
	{
		testimony:'Everyone there from sales, service and especially parts are the best! Very friendly, professional and genuinely concerned with your visit. Will continue to drive the two counties I drive to get service and parts.',
		name:'Kunal',
		rating:5
	},
	{
		testimony:'Everyone there from sales, service and especially parts are the best! Very friendly, professional and genuinely concerned with your visit.',
		name:'Kunal',
		rating:5
	},
	{
		testimony:'Everyone there from sales, service and especially parts are the best! Very friendly, professional and genuinely concerned with your visit. Will continue to drive the two counties I drive to get service and parts.',
		name:'Kunal',
		rating:5
	},
	{
		testimony:'Everyone there from sales, service and especially parts are the best! Very friendly, professional and genuinely concerned with your visit. Will continue to drive the two counties I drive to get service and parts.',
		name:'Kunal',
		rating:5
	}
]

const blogItems = [
	{
		img:'https://ope.buckeyepowersales.com/wp-content/uploads/fly-images/9796/Toro-GrandStand-promo-318x512-c.jpg',
		title:'Celebrating Queer Art: reimagining iconic logos in the style of renowned queer artists',
		'desc':'Features the Toro-developed HyperCell battery system.',
		'link':'#'
	},
	{
		img:'https://ope.buckeyepowersales.com/wp-content/uploads/fly-images/9839/Toro-Stripe-promo-318x512-c.jpg',
		title:'Toro Stripe - Coming Soon!',
		'desc':'Features the Toro-developed HyperCell battery system.',
		'link':'#'
	},
	{
		img:'https://ope.buckeyepowersales.com/wp-content/uploads/fly-images/9796/Toro-GrandStand-promo-318x512-c.jpg',
		title:'Toro GrandStand Revolution',
		'desc':'Features the Toro-developed HyperCell battery system.',
		'link':'#'
	}
]




export default class Home extends React.Component {
	constructor(){
		super();
		this.state ={
			width:window.innerWidth,
			products:null,
			category:null,
			categoryrent:null,
			page:1,
			hasMore:true,
			sliderLoading:false,
			homeSlider:null,
			upcoming:null,
			testimonials:testimonials,
			blogItems:[],
			swiper:null,
		}
		this.prevRef = React.createRef();
		this.nextRef = React.createRef();
		this.upPrevRef = React.createRef();
		this.upNextRef = React.createRef();
	}
	componentDidMount = () => {
		AOS.init();
		
		this.get_home_slider()
		this.get_parent_category()
		this.getTestimonials()
		this.getBlogs();
		this.get_rent_category()
	}




 getBlogs = () => {
      fetch(`${API_ENDPOINT}/get_blogs`,{
         method: 'GET',
         headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('Blogs=====',result)
         if(result.status === 1){
			this.setState({
				blogItems:result.data,
			})
		}
        
      })
   }
	 getTestimonials = (pg) => {
      fetch(`${API_ENDPOINT}/get_testimonials?page=1&limit=12`,{
         method: 'GET',
         headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('testimonials=====',result)
         if(result.status === 1){
			this.setState({
				testimonials:result.data,
			})
		}
        
      })
   }

	get_home_slider = () => {
		
			
		this.setState({
			sliderLoading:true,
		})	
		fetch(`${API_ENDPOINT}/get_home_slider`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('slider======',result)
			this.setState({
				sliderLoading:false,
			})
			if(result.status === 1){
				this.setState({
					homeSlider:result.data,
				})
			}
		})
	}

	
	get_parent_category = () => {

		fetch(`${API_ENDPOINT}/get_sale_category`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('category======',result)
			this.setState({
				category: result.data
			})
			
		})
	}


	get_rent_category = () => {

		fetch(`${API_ENDPOINT}/get_rent_category`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('category======',result)
			this.setState({
				categoryrent: result.data
			})
			
		})
	}
	

	fetchData = () => {
		this.getProducts(this.state.page)
	}

	/*getUpcomingProducts = () => {
		fetch(`${API_ENDPOINT}/upcoming_product_home`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('upcoming product', result)
			if(result.status === 1){
				this.setState({
					upcoming:result.data
				})
			}
		})
	}
	*/

	slideChange = (e) => {
		console.log('========',e);

	}

	_onReady(event) {
	const player = event.target;
    player.pauseVideo();
		 /*if (this.state.swiper && this.state.swiper !== null) {
        this.state.swiper.slideNext();
    	}*/
		//this.swiper.slideTo(1);
    // access to player in all event handlers via event.target
    //event.target.pauseVideo();
  }

  onPlayerStateChange(event){
  	const player1 = event.target;
  	console.log("player1",player1);
    //player.playVideo();
  }

	render(){
		const siteMetadata = {
			url: `${APP_URL}`,
			title:  `Dixie Chopper Dealer, Parts distributor, and Commercial Mower  Service - ${APP_NAME}`,
			description: `Dixie Chopper Dealer, Parts distributor, and Commercial Mower  Service`,
			image: `./img/logo.png`,
		 }
		 const {category, homeSlider, sliderLoading ,testimonials, blogItems, categoryrent } = this.state

		 
	return (
		<ScrollPageTop>
		<ReactSEOMetaTags
			render={(el: React.ReactNode) => <Helmet><link rel="preconnect" href="https://i3.ytimg.com" /><link rel="preconnect" href="https://www.youtube.com" />
			<link rel="preload" as="image" href="https://i3.ytimg.com/vi/kUrDaxumwpw/maxresdefault.jpg" /><link rel="preload" as="image" href="https://i3.ytimg.com/vi/BULVFNkgrQU/maxresdefault.jpg" />{el}</Helmet>}
			website={{ ...siteMetadata }}
			breadcrumb={[
        		{ title: 'Home', url: `${APP_URL}` },
			]}
		/>
		<Header />
		<div className="min-h-min lg:min-h-screen">
			
		<VideoSlider />
								
			<div className="bg-white py-8">
				<div className="px-5 md:px-8 lg:px-8 flex flex-col lg:flex-row py-6">
					{/* <div className="w-full lg:w-1/3 pb-10 lg:pr-20">
						<h1 className="text-3xl xl:text-5xl text-light-text">Machines for Green Season</h1>
						<p className="text-light-text mt-6 leading-8">Unlike big box stores or single-brand dealers, we care about more than a sale. We take pride in our spring and summer equipment so you can take pride in your yard work. If you have the right equipment and just need a tune-up, our manufacturer-trained technicians are standing by. If you’d prefer to shop new and used lawn equipment in-person, visit one of our Ohio showroom locations.</p>
					</div> */}
					<div className="w-full  lg:mx-auto hover:opacity-80 ">
						<div className="grid grid-cols-1 lg:grid-cols-2 " >
							{
							category	&& category.map((val, key) => (
							<div className="text-center bg-light dark:bg-gray-700 py-4 flex flex-col overflow-hidden cursor-pointer catgeory-sep dark:border-accent lg:ml-5 hover:opacity-100 hover:border-accent  ">
								<Link to={`${Links.CATEGORY}/${val.slug}`} class="flex items-center justify-between">
									<div className="text-3xl text-heading text-center px-2.5 block dark:text-body font-oswald font-light	">
									{val.cat_name}
									{/*<p className="font-body text-sm text-left">{val.description}</p>*/}
									</div>
									<div className="w-32 h-36">
										<LazyLoadImage src={`${IMG_URL}${val.cat_image}`} className="w-32 h-36 object-contain rounded" alt="" />
									</div>
								</Link>
							</div>
							))
							}
						
						</div>
					</div>
				</div>
			</div>
			{/* <div className="px-8 py-10">
				<div className="flex justify-center mt-10 pb-10">
					<h1 className="px-3 text-3xl xl:text-5xl text-gray-color font-light">Equipment For Rent</h1>
					
				</div>	
				<div className="px-5 md:px-8 lg:px-8 flex flex-col lg:flex-row py-6">
					
					<div className="w-full  lg:mx-auto hover:opacity-80 ">
						<div className="flex flex-row" >
							{
							categoryrent	&& categoryrent.map((val, key) => (
							<div className="text-center bg-light dark:bg-gray-700 py-4 flex  overflow-hidden cursor-pointer catgeory-sep dark:border-accent lg:ml-5 hover:opacity-100 hover:border-accent  ">
								<Link to={`${Links.CATEGORY}/${val.slug}`} class="flex flex-col items-center justify-between">
									<div className="w-32 h-36 ">
										<LazyLoadImage src={`${IMG_URL}${val.cat_image}`} className="w-32 h-36 object-contain rounded" alt="" />
									</div>
									<div className="text-3xl text-heading text-center px-2.5 block dark:text-body font-oswald font-light	">
									{val.cat_name}
									
									</div>
								</Link>
							</div>
							))
							}
						
						</div>
					</div>
				</div>	
			</div> */}
			<div className="bg-gray-200 px-8 py-10">
			<div className="flex justify-center mt-10 pb-10">
				<h1 className="px-3 text-3xl xl:text-5xl text-gray-color font-light">Partnership That Makes An Impact</h1>
			</div>				

			<div className="flex flex-1 mt-10">
				<main className="block w-full ltr:xl:pl-0 rtl:xl:pr-0 rtl:xl:pl-5 ">
					<div name="grid" className="xl:px-0">
						<div className="relative w-full pb-6">
							<Swiper
						      spaceBetween={10}
						      slidesPerView={3}
						      onSlideChange={() => console.log('slide change')}
						      onSwiper={(swiper) => { console.log(swiper); }}
						      id="swiper-testimonial"
						      breakpoints={upcomingBreakpoints}
        						modules={[Pagination, Navigation]}
								navigation={{
									prevEl: this.prevRef.current, // Assert non-null
									nextEl: this.nextRef.current, // Assert non-null
								}}
						    >
						    {testimonials  && testimonials.map((val, key) => ( 
						     <SwiperSlide >
						      <div className="flex flex-col p-8 justify-start ">
									<div className="flex items-center justify-start mb-5">{showRating(val.rating)}</div>

									<p className="font-light text-md leading-7 text-light-text antialiased text-gray-color">
									{val.testimony}
									</p>
									<div className="font-normal text-md text-gray-color capitalize leading-9 pt-5">{val.name}</div>
						      </div>
							</SwiperSlide>
						     
						    ))
						    }
						    </Swiper>
							 <div
								ref={this.prevRef}
								className="product-gallery-prev absolute top-1/3 z-30 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center  bg-gray-800 text-white shadow-xl transition-all duration-200 hover:bg-gray-100 ltr:-left-4 rtl:-right-4 md:-mt-5 md:h-32 md:w-12 ltr:md:-left-5 rtl:md:-right-5 dark:bg-gray-500 dark:text-light"
								>
									<ChevronLeft className="h-4 w-4" />
								</div>
							<div
								ref={this.nextRef}
								className="product-gallery-next absolute top-1/3 z-30 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center  bg-gray-800 text-white shadow-xl transition-all duration-200 hover:bg-gray-100 ltr:-right-4 rtl:-left-4 md:-mt-5 md:h-32 md:w-12 ltr:md:-right-5 rtl:md:-left-5 dark:bg-gray-500 dark:text-light"
								>
									<ChevronRight className="h-4 w-4" />
							</div>
						</div>
					</div>
					<div className="flex justify-center mt-5"><Link to={Links.TESTIMONIALS} className="flex items-center text-xl hover:underline transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"><span>Go to Testimonials</span><BsArrowRight className="ml-2" /></Link></div>
				</main>
			</div>
			</div>
			<div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
				{blogItems && blogItems.length > 0 ?

				<div className="pt-10 lg:py-10 px-8 ">
					<h2 className='text-3xl lg:text-4xl mb-10 font-light text-gray-color '>{blogItems[0]?.title}</h2>
					<p >{blogItems[0]?.content.length > 60 ?
										    `${blogItems[0]?.content.substring(0, 60)}...` : blogItems[0]?.content
										  }
					</p>
					<div className="my-5">
						<LazyLoadImage src={blogItems[0]?.img_full}  className="w-full object-cover rounded h-[370px]" />
					</div>
				</div>
				:null
				}
				<div className="border-t lg:border-l lg:pl-8">
					<div className="py-10 px-8">
						<h2 className='text-3xl lg:text-4xl mb-10 font-light text-gray-color'>Tips, trends and tons of inspiration</h2>
							{
								blogItems && blogItems.map((val, key) => (
									<Link to={val.link} target="_blank" className="flex mb-5">
										<div className="w-1/3">
											<LazyLoadImage src={val.img} className="w-48 h-28 object-cover rounded hover:animate-pulse hover:delay-200 " />
										</div>
										<div className="w-2/3 pl-4">
											<p className='text-2xl font-normal text-gray-color lg:text-2xl mb-2 font-body hover:underline'>{val.title}</p>
											<p className='mb-5'>
											{val.content.length > 60 ?
										    `${val.content.substring(0, 60)}...` : val.content
										  }
											</p>

										</div>
									</Link>
								))
							}
							<div className="flex mt-10"><Link to={Links.BLOG} target="_blank" className="flex items-center text-xl hover:underline transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"><span>Take me to the blog!</span><BsArrowRight className="ml-2" /></Link></div>
					</div>
				</div>
				{/* <div className="flex justify-center">
					<h1 className="px-3 text-3xl xl:text-5xl text-light-text">What's New at PowerTripOhio</h1>
				</div>
				<div className="mt-8 px-8">
					<div className="grid grid-cols-2 lg:grid-cols-4 gap-6 mb-10">
						{
							blogItems && blogItems.map((val, key) => (
							<Link to="#" className="relative blog-cnt-hover rounded overflow-hidden">
								<img src={val.img} />
								<div className="blog-cnt px-5">
									<h2 className='text-2xl lg:text-3xl mb-5'>{val.title}</h2>
									<div>{val.desc}</div>
								</div>
							</Link>
							))
						}
					</div>
				</div> */}
			</div>
		</div>
		<Footer />
		</ScrollPageTop>
		);
	}
};
