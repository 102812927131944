import React from "react";
import { motion } from 'framer-motion';
import {NavbarIcon, UserIcon, HomeIcon, ShoppingBagIcon, CloseIcon} from '../custom-icons'
import { Drawer } from "flowbite";
import MobileMenuDrawer from './mobile-menu-drawer'
import Logo from './logo';
import { useNavigate } from "react-router-dom";
import {Links} from "../routes/PathLink"
import {API_ENDPOINT} from '../components/config/Common'
import Collapse from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import { BiDownArrow } from "react-icons/bi";
import classNames from 'classnames';

const MobileNav = (props) => {
   const [category, setCategory] = React.useState(null)
   const [isActive, setIsActive] = React.useState(null)
   const [isActiveretnt, setIsActiveRent] = React.useState(null)
   const [categoryrent, setCategoryRent] = React.useState(null)
   
   const navigate = useNavigate();

   React.useEffect(() => {      
      getAllCategory()
      getRentCategory()
   },[])

   const getAllCategory = () => {
      fetch(`${API_ENDPOINT}/get_sale_category`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('side bar categories======',result)
			setCategory(result.data)
			
		})
   }
   //

    const getRentCategory = () => {
      fetch(`${API_ENDPOINT}/get_rent_category`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('side bar categories======',result)
         setCategoryRent(result.data)
         
      })
   }

   const openAuthMenu = () => {
      //console.log('login------')
      const options = {
         placement: 'right',
         backdrop: true,
         bodyScrolling: false,
         edge: false,
         edgeOffset: '',
         backdropClasses: 'cart-drawer bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30',
         onHide: () => {
             //console.log('drawer is hidden');
             //setDrawerVisible(false)
         },
         onShow: () => {
             //console.log('drawer is shown');
             //setDrawerVisible(true)
         },
         onToggle: () => {
             console.log('drawer has been toggled');
         }
       };
     const $targetEl = document.getElementById('mobile-menu-drawer');
     const drawer: DrawerInterface = new Drawer($targetEl, options);
     drawer.show();
   }

   const openLeftMenu = () => {
      const options = {
         placement: 'left',
         backdrop: true,
         bodyScrolling: false,
         edge: false,
         edgeOffset: '',
         backdropClasses: 'cart-drawer bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30',
       };
     const $targetEl = document.getElementById('mobile-menu-left-drawer');
     const drawer: DrawerInterface = new Drawer($targetEl, options);
     drawer.show();
   }

   const closeLeftDrawer = () => {
      //console.log('close drawer')
      const options1 = {
          placement: 'left',
          backdrop: false,
          bodyScrolling: true,
          edge: false,
          edgeOffset: '',
          backdropClasses: 'bg-gray-900 bg-opacity-0 dark:bg-opacity-0 fixed',
        };
      const $targetEl = document.getElementById('mobile-menu-left-drawer');
      const drawer: DrawerInterface = new Drawer($targetEl, options1);
      drawer.hide();
      let cartDrawer = document.querySelector(".cart-drawer");
      let body = document.querySelector("body");
      cartDrawer.remove();
      body.classList.remove("overflow-hidden");
   }

   const closeRightMenuDrawer = () => {
      //console.log('close drawer')
      const options1 = {
          placement: 'right',
          backdrop: false,
          bodyScrolling: true,
          edge: false,
          edgeOffset: '',
          backdropClasses: 'bg-gray-900 bg-opacity-0 dark:bg-opacity-0 fixed',
        };
      const $targetEl = document.getElementById('mobile-menu-drawer');
      const drawer: DrawerInterface = new Drawer($targetEl, options1);
      drawer.hide();
      let cartDrawer = document.querySelector(".cart-drawer");
      let body = document.querySelector("body");
      cartDrawer.remove();
      body.classList.remove("overflow-hidden");
   }

   const go_to_link = (link) => {
      //console.log(link)
      let cartDrawer = document.querySelector(".cart-drawer");
      let body = document.querySelector("body");
      cartDrawer.remove();
      body.classList.remove("overflow-hidden");
      navigate(link);
   }

   const getActiveKey = (key) => {
      //console.log('getActiveKey======',key[0])
      if(key[0]){
         setIsActive(true)
      }else{
         setIsActive(false)
      }
   }

   const getActiveKeyrent =(key)=>{
       //console.log('getActiveKeyRent ======',key[0])
      if(key[0]){
         setIsActiveRent(true)
      }else{
         setIsActiveRent(false)
      }
   }

   return (
      <>
      <div className="hidden">
         <nav className="fixed bottom-0 z-10 flex h-12 w-full justify-between bg-light py-1.5 px-2 shadow-400 ltr:left-0 rtl:right-0 md:h-14">
            <motion.button
            whileTap={{ scale: 0.88 }}
            className="flex h-full items-center justify-center p-2 focus:text-accent focus:outline-none"
            onClick={openLeftMenu}>
               <NavbarIcon  />
            </motion.button>
            <motion.button
               whileTap={{ scale: 0.88 }}
               className="flex h-full items-center justify-center p-2 focus:text-accent focus:outline-none"
            >
               <HomeIcon />
            </motion.button>
            <motion.button
               whileTap={{ scale: 0.88 }}
               className="product-cart relative flex h-full items-center justify-center p-2 focus:text-accent focus:outline-none"
               onClick={props.drawerVisible}
            >
               <ShoppingBagIcon />
              
            </motion.button>
            
         </nav>   
      </div>
      <MobileMenuDrawer closeRightMenuDrawer={closeRightMenuDrawer} />

      <div id="mobile-menu-left-drawer" className="fixed top-0 left-0 z-40 h-screen overflow-y-auto transition-transform -translate-x-full bg-white w-80 dark:bg-gray-800" tabIndex="-1" aria-labelledby="drawer-right-label">
         
         <div className="fixed top-0 z-10 w-80 max-w-md flex items-center justify-between border-b border-border-200 border-opacity-75 bg-light py-4 px-6">
            <a className="inline-flex w-24 md:w-auto" href="/">
               <span className="relative h-10 w-32 md:w-40">
                  <Logo />
               </span>
            </a>
            <button className="flex h-7 w-7 items-center justify-center rounded-full bg-gray-100 text-muted transition-all duration-200 hover:bg-accent hover:text-light focus:bg-accent focus:text-light focus:outline-none ltr:ml-3 ltr:-mr-2 rtl:mr-3 rtl:-ml-2" onClick={closeLeftDrawer}>
               <span className="sr-only"></span>
               <CloseIcon className="h-3 w-3" />
            </button>
         </div>
         
         <div className="pt-20">
            <ul className="flex-grow">
               <li>
                  <button className="block cursor-pointer px-5 py-3 text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent md:px-8" onClick={() => go_to_link(Links.PARTSLOOKUP)}>Parts Lookup</button>
               </li>
               {/*<li>
                  <button className="block cursor-pointer px-5 py-3 text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent md:px-8" onClick={() => go_to_link(Links.CATEGORY)}>Equipment For Sale</button>
               </li>
               */}
               <li>
                  <Collapse
                     accordion={true}
                     defaultActiveKey={null}
                     expandIcon={() => null}
                     className="px-5 py-3 bg-light"
                     openMotion={motion}
                     onChange={getActiveKey}
                  >
                     <Collapse.Panel
                        key={`shop-menu`}
                        header={
                        <button className="block cursor-pointer text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent md:px-8 flex items-center justify-between w-full">
                           <span>Equipment For Sale</span>
                           <span className={classNames("ltr:ml-auto ltr:mr-4 rtl:ml-4 rtl:mr-auto transition-all",{
                              'text-accent rotate-180':isActive
                           })}>
                              <BiDownArrow />
                           </span>
                        </button>
                        }
                     >
                        <ul>
                        {
                           category?.map((val, key) => (
                           <li>
                              <button className="block cursor-pointer px-5 py-3 text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent md:px-8" onClick={() => go_to_link(`${Links.CATEGORY}/${val.slug}`)}>{val.cat_name}</button>
                           </li>
                        ))
                        }
                        </ul>
                     </Collapse.Panel>
                  </Collapse>
               </li>

                <li>
                  <Collapse
                     accordion={true}
                     defaultActiveKey={null}
                     expandIcon={() => null}
                     className="px-5 py-3 bg-light"
                     openMotion={motion}
                     onChange={getActiveKeyrent}
                  >
                     <Collapse.Panel
                        key={`shop-menu-rent`}
                        header={
                        <button className="block cursor-pointer text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent md:px-8 flex items-center justify-between w-full">
                           <span>Equipment For Rent</span>
                           <span className={classNames("ltr:ml-auto ltr:mr-4 rtl:ml-4 rtl:mr-auto transition-all",{
                              'text-accent rotate-180':isActiveretnt
                           })}>
                              <BiDownArrow />
                           </span>
                        </button>
                        }
                     >
                        <ul>
                        {
                           categoryrent?.map((val, key) => (
                           <li>
                              <button className="block cursor-pointer px-5 py-3 text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent md:px-8" onClick={() => go_to_link(`${Links.CATEGORY}/${val.slug}`)}>{val.cat_name}</button>
                           </li>
                        ))
                        }
                        </ul>
                     </Collapse.Panel>
                  </Collapse>
               </li>
                <li>
                  <button className="block cursor-pointer px-5 py-3 text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent md:px-8" onClick={() => go_to_link(Links.SERVICES)}>Services</button>
               </li>
               <li>
                  <button className="block cursor-pointer px-5 py-3 text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent md:px-8" onClick={() => go_to_link(Links.CONTACT)}>Contact</button>
               </li>
            </ul>
         </div>
      </div>
      </>
   )
}

export default MobileNav;