import React, {useState, useEffect, useRef , useMemo} from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Collapse from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import ScrollPageTop from '../../routes/ScrollPageTop'

import { Link , useParams, useNavigate, createSearchParams } from "react-router-dom";
import {API_ENDPOINT, APP_URL, APP_NAME, IMG_URL, LIMIT} from '../config/Common'
import {SearchIcon, CloseIcon } from "../../custom-icons";
import cn from 'classnames';
import { motion as ms } from 'framer-motion';
import motion from '../../_utils/motionUtil.ts'
import { BiDownArrow } from "react-icons/bi";
import classNames from 'classnames';

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import PartsCard from '../../cards/PartsCard'
import {Links} from '../../routes/PathLink'
import { useSearch } from '../../ui/search/search.context.tsx';
import InfiniteScroll from "react-infinite-scroll-component";
import Search from '../../ui/search/search.tsx'

import NoRecords from '../NoRecords'
import Seo from '../seo/seo'
import {isMobile} from "react-device-detect"
import { Range, getTrackBackground } from 'react-range';

import Button from '../../ui/Button.tsx'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import PartRowCard from '../../cards/PartRowCard';
const STEP = 100;
const MIN = 1000;
const MAX = 500000;
const rtl = false

const classes = {
  normal:
    'bg-light ltr:pl-6 rtl:pr-6 ltr:pr-14 rtl:pl-14 ltr:rounded-tr-none rtl:rounded-tl-none ltr:rounded-br-none rtl:rounded-bl-none  border ltr:border-r-0 rtl:border-l-0 border-transparent focus:border-accent',
  minimal:
    'search-minimal bg-white ltr:pl-10 rtl:pr-10 ltr:pr-4 rtl:pl-4 ltr:md:pl-14 rtl:md:pr-14 border border-transparent focus:border-accent focus:bg-light',
  flat: 'bg-white ltr:pl-10 rtl:pr-10 ltr:pr-4 rtl:pl-4 ltr:md:pl-14 rtl:md:pr-14 border-0',
  'with-shadow':
    'search-with-shadow bg-light ltr:pl-10 rtl:pr-10 ltr:pr-12 rtl:pl-12 ltr:md:pl-14 rtl:md:pr-14 focus:bg-light border-0',
};

const PartsSearch = (props) => {
   const {className , variant , label , ...rest}  = props;
   const navigate = useNavigate();
   const [process, setProcess] = useState(false)
   const [value, setValue] = useState(null);
   const [partslist, setPartslist] = useState(null)
   const [totalRec, setTotalRec] = useState(0)
   const [page, setPage] = useState(1)
   const [hasMore, setHasMore] = useState(true)

   const myRef = useRef(null)
    
   const {slug} = useParams()

    useEffect(() => {
        //getPartDetail();
        //getPartslists(1);
      //getPartslists(1)
      props.setSerachValue(value);
   },[value])

     

/*const filteredUsers = useMemo(
  () =>{
    console.log("VALUE",value);
  },
  [value]
);
*/

   /*const getPartslists = (pg) => {

      let paginate = `page=${pg}&limit=${LIMIT}`;
     
      fetch(`${API_ENDPOINT}/partslist/${slug}?${paginate}&search=${value}`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('cat products======',result)
         //setProducts(result.data)
         if(pg === 1){
            setPartslist(result.data)
         }else{
            setPartslist([...partslist, ...result.data])
         }
         let nextPage = page + 1;
         setPage(nextPage)
         setTotalRec(result.total)

         if(result.data.length < LIMIT){
            setHasMore(false)
         }else{
            setHasMore(true)
         }

         
         if(isMobile){
            myRef?.current?.scrollIntoView( { behavior: 'smooth', block: 'start' } )
         }
         setProcess(true)
         
         /* let p = document.getElementById('product-list')
         p.scrollIntoView( { behavior: 'smooth', block: 'start' } ); */
     /* })
   }
   */


const onSubmit = (e) =>{
    e.preventDefault();
   console.log(e);

}

const onClearSearch = () =>{
   props.setSerachValue('');
   setValue('');
}
   return(
      <form  onSubmit={onSubmit} className={cn('w-full mb-3 ', className)}>
      <div
        className={cn('relative flex rounded md:rounded-lg', {
          'h-14 shadow-900': variant === 'normal',
          'h-11 md:h-12': variant === 'minimal',
          'h-auto !rounded-none': variant === 'flat',
          'h-16 shadow-downfall': variant === 'with-shadow',
        })}
      >
        <label htmlFor={label} className="sr-only">
          {label}
        </label>
        <input
          id={label}
          type="text"
          value={value}
          autoComplete="off"
          className={cn(
            'search bg-white item-center flex h-full w-full appearance-none overflow-hidden truncate rounded-lg text-sm text-heading placeholder-gray-500 transition duration-300 ease-in-out focus:outline-none focus:ring-0 pl-10',
            {
              'placeholder:text-slate-400': variant === 'flat',
            },
            classes[variant]
          )}
          {...rest}
          data-variant={variant}
             onChange={(e)=>{
               const {name, value} = e.target;
               setValue(value);
             }
            
          }
        />
        {value && (
          <button
            type="button"
            onClick={onClearSearch}
            className={cn(
              'absolute flex h-full w-10 cursor-pointer items-center justify-center text-body transition-colors duration-200 hover:text-accent-hover focus:text-accent-hover focus:outline-none md:w-14',
              {
                'ltr:right-36 rtl:left-36': variant === 'normal',
                'ltr:right-0 rtl:left-0': variant !== 'normal',
              }
            )}
          >
            <span className="sr-only">close</span>
            <CloseIcon className="h-3.5 w-3.5 md:h-3 md:w-3" />
          </button>
        )}

        {variant === 'normal' ? (
          <button className="flex h-full min-w-[143px] items-center justify-center rounded-lg bg-accent px-8 font-semibold text-light transition-colors duration-200 hover:bg-accent-hover focus:bg-accent-hover focus:outline-none ltr:rounded-tl-none ltr:rounded-bl-none rtl:rounded-tr-none rtl:rounded-br-none">
            <SearchIcon className="h-4 w-4 ltr:mr-2.5 rtl:ml-2.5" />
          </button>
        ) : (
          <button className="absolute flex h-full w-10 items-center justify-center text-body transition-colors duration-200 hover:text-accent-hover focus:text-accent-hover focus:outline-none ltr:left-0 rtl:right-0 md:w-14">
            <span className="sr-only"></span>
            <SearchIcon className="h-4 w-4" />
          </button>
        )}
      </div> 
    </form>
   )
}

export default PartsSearch