
export const Links = {
   HOME:'/',
   LOGOUT:'/logout',
   CONTACT:'/contact',
   ABOUT:'/about',
   HELP:'/help',
   TERMS:'/terms-and-conditions',
   PRIVACY:'/privacy-policy',
   PRODUCT:'/product',
   CATEGORY:'/equipment-for-sale',
   EQUIPMENTFORRENT:'/equipment-for-rent',
   TESTIMONIALS:'/testimonials',
   CARRIER:'/careers',
   UPCOMING_PRODUCTS:'/upcoming-products',
   FREE_CONSULTATION:'/free-consultation',
   WHYCTMRIHUB:'/why-ctmri-hub',
   AERBLICENSING:'/aerb-licensing',
   PARTSREPAIR:'/parts-repair',
   BLOG:'/blog',
   SERVICES:'/services',
   PARTSLOOKUP:'/partslookup',
   FINANCE:'/finance',
}