
import {APP_URL, IMG_URL, amount_format,discount} from '../components/config/Common'
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { BiRupee } from "react-icons/bi";
import { motion as ms } from 'framer-motion';
import {Links} from '../routes/PathLink'
import { Link } from "react-router-dom";
const CatlougeCard = ({
   item,
   upcoming
}:props) => {

   return(
    <Link to={`${Links.PARTSLOOKUP}/model/${item?.slug}`} >
     <div className="drop-shadow-lg rounded-lg  bg-contain bg-no-repeat  bg-center   h-72  px-2" style={{backgroundImage:`url(${APP_URL}/img/othercategories1651519905.png)`}} >
        <h2 className="flex items-center justify-center h-full text-white text-xxl fon ">{item?.name}</h2>
      </div>
    </Link>
   )
}

export default CatlougeCard;