
import {APP_URL, IMG_URL, amount_format,discount} from '../components/config/Common'

import { BiRupee } from "react-icons/bi";
import { motion as ms } from 'framer-motion';
import {Links} from '../routes/PathLink'
import { Link } from "react-router-dom";
const PartRowCard = ({
   item,
   upcoming
}:props) => {

   return(
     <div className="bg-light drop-shadow-lg rounded-lg  ">
      <div className="mb-0 border-b-2 text-black  bg-gray-200  border-gray-900 flex flex-col flex-wrap items-center justify-between gap-x-3 text-base font-bold text-heading sm:flex-row lg:flex-nowrap">
            <div className="  w-full px-2 py-0 border-black ">
              <span className="mb-2 block lg:mb-0 lg:inline-block lg:ltr:mr-4 lg:rtl:ml-4">
              </span>
           </div>
          <div>
        </div>
      </div>
       <div className="px-2 py-2 w-full ">
       <div className="flex w-full ">
            <div className="w-1/2 justify-center align-center font-bold">
                {item.ref_no}
            </div> 
            <div className="w-1/2 " >
               <div className="text-sm font-bold">{item.part_no}</div>
               <div className="text-sm">{item.part_desc}</div>
               <div className="mt-2">
                 <a href="tel:614-642-1030"  className="block " >
                    <button className="flex items-center justify-center w-full border-accent bg-accent border-2 mb-2 px-2 py-2 hover:bg-accent-500 text-white text-sm">Call For Price </button>
                </a>
               </div>
          
            </div>
         </div>
          </div>
    </div>
   )
}

export default PartRowCard;