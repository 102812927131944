
import {APP_URL, IMG_URL, amount_format,discount} from '../components/config/Common'
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { BiDollar } from "react-icons/bi";
import { motion as ms } from 'framer-motion';
import {Links} from '../routes/PathLink'
import { Link } from "react-router-dom";
const ServiceCard = ({
   item,
   upcoming
}:props) => {

   return(
     <div className="bg-light drop-shadow-lg overflow-hidden">
         <div className="mb-0 bg-[#515151] flex flex-col flex-wrap items-center justify-between gap-x-8 text-base text-zinc-300 text-heading sm:flex-row lg:flex-nowrap">        
            <div className="w-full px-4 py-2">
               <span className="mb-2 block lg:mb-0 lg:inline-block lg:ltr:mr-4 lg:rtl:ml-4">
               {item.title}
               </span>
            </div>
         </div>
         <div className="px-4 py-3 w-full">
            <div className="flex w-full items-center">
               <div className="w-1/2 flex justify-center items-center">
               {
                  item.image
                  ?
                  <LazyLoadImage src={`${IMG_URL}${item.image}`} className="h-28 w-1/2 sm:h-24 object-contain" alt={item.title} sizes="10vw" />
                  :
                  <LazyLoadImage src={`${APP_URL}/img/no-image-available.png`} alt={item.title} sizes="10vw" />
               }
               </div>
               <div className="w-1/2" >
                  <div className="flex flex-col justify-end items-center">
                     <Link to={`${Links.SERVICES}/${item.slug}`} className="block w-full" >
                     <button className="border text-light text-sm mb-2 px-2 py-2 border-accent bg-accent hover:bg-accent-300 hover:border-accent-300 w-full">Service Details </button>
                     </Link>
                     <Link to={`${Links.SERVICES}/${item.slug}?cur=requestinfo`} className="block w-full" >
                     <button className="border-gray-300 border px-2 py-2 text-sm hover:bg-gray-100 w-full">Request Information</button>
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default ServiceCard;