
import React, {useState, useEffect, useRef} from "react";
import {APP_URL, APP_NAME, API_ENDPOINT, IMG_URL} from '../components/config/Common'
import YouTube from 'react-youtube';

// import Swiper core and required modules
import { Navigation, Pagination, Thumbs, Autoplay } from 'swiper';
import { Swiper, SwiperSlide , useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
const opts = {
      height: '100%',
      width: '100%',
      playerVars: {
        enablejsapi:1
      },
    };
const VideoSlider = (props) => {

  const [sliderLoading,setSliderLoading] = useState(false);
  const [homeSlider , setHomeSlider] = useState(null);
    const [currentindex , setCurrentindex] = useState(0);
   const [showinitalplaceholder,setShowinitalplaceholder] = useState(true);

    const [swiper, setSwiper] = useState();
    //const swiper = useSwiper();

  const vidRef = useRef(null);
      const [player, setPlayer] = useState(null);
      const [player2, setPlayer2] = useState(null);


   useEffect(() => {
      get_home_slider()
   },[]);

  const get_home_slider = () => {
    
      
   setSliderLoading(true) ; 
    fetch(`${API_ENDPOINT}/get_home_slider`,{
      method: 'GET',
      headers : {        
        'Content-Type': 'application/json',
        'Accept': 'application/json', 
      }
    }).then(function (response) {
        return response.json();
    }).then( (result)=> {
      //console.log('slider======',result)
       setSliderLoading(false) ; 
      if(result.status === 1){
       setHomeSlider(result.data)
      }
    })
  }
  const _onReady = (event) => {
    console.log("ON READY",event);
              //setPlayer(event.target);
    setShowinitalplaceholder(false);
    event.target.playVideo();
    setPlayer2( event.target);
    //player.playVideo();
    // access to player in all event handlers via event.target
    //event.target.pauseVideo();
  }

  const _onReady2 = (event) => {
    console.log("ON READY",event);
    setPlayer(event.target);
    //event.target.playVideo();
    //player.playVideo();
    // access to player in all event handlers via event.target
    //event.target.pauseVideo();
  }

  const _onEnd = (event) => {
     console.log("ON End",currentindex);
     swiper.slideTo(currentindex+1)
     player.playVideo();
     player2.stopVideo();

    //event.target.playVideo();
    // access to player in all event handlers via event.target
    //event.target.pauseVideo();
  }

  const _onEnd2 = (event) =>{
    console.log("ON End",currentindex);
      swiper.slideTo(currentindex-1)
      //event.target.playVideo();
      player2.playVideo();
      player.stopVideo();
  }

 return(
      <div className="">
        <div className="-z-1 overflow-hidden">
          <div className="bg-white relative">
            <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden " id="banner">
              <div className="swiper-wrapper">
                <div className="swiper-slide swiper-slide-active">
                  <div className="relative w-full ">
                 
                 {
                  !sliderLoading 
                  ?
                  <Swiper
                    // install Swiper modules
                    modules={[Autoplay, Navigation]}     
                    onIndexChanged={(index) =>{
                      console.log("INDEX CHNAGE ",index);
                        setCurrentindex(index);

                    } }                
                    loop={false}
                    autoplay={false}
                    onSwiper={setSwiper}
                    className="absolute "
                  >
                    <SwiperSlide>
                      <div className="h-96 lg:h-[40rem] md:h-[25rem] ">
                      {showinitalplaceholder ?
                      (<img src="https://i3.ytimg.com/vi/kUrDaxumwpw/maxresdefault.jpg" className=" h-96 lg:h-[40rem] md:h-[25rem] w-full object-cover" />):
                      null}
                      {/*<iframe id="ytplayer" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="DIXIE CHOPPER Tony Testimonial" src="https://www.youtube.com/embed/kUrDaxumwpw?enablejsapi=1&amp;autoplay=1&amp;autohide=1&amp;wmode=transparent&amp;iv_load_policy=3&amp;branding=0&amp;fs=0&amp;controls=0&amp;mute=1&amp;modestbranding=1&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;playlist=kUrDaxumwpw&amp;widgetid=1"  class="ae-media ae-youtube slider-iframe" width="640" height="360" frameborder="0"></iframe>*/}
                      <YouTube id="sadjad" className="" iframeClassName="ae-media ae-youtube slider-iframe " videoId="kUrDaxumwpw"  opts={{
                        height: '100%',
                          width: '100%',
                        playerVars: {
                           controls:0,
                           autoplay: 0,
                           rel:0,
                           showinfo:0,
                           modestbrowsing:1,

                        },
                      }} ref={vidRef}  onReady={_onReady} onEnd={_onEnd} loading={'lazy'}    /> 
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="h-96 lg:h-[40rem] md:h-[25rem] ">
                      {showinitalplaceholder ?
                      (<img src="https://i3.ytimg.com/vi/BULVFNkgrQU/maxresdefault.jpg" className="object-cover h-96 lg:h-[40rem] md:h-[25rem] w-full" />):
                      null}
                      {/*<iframe id="ytplayer" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="DIXIE CHOPPER Tony Testimonial" src="https://www.youtube.com/embed/kUrDaxumwpw?enablejsapi=1&amp;autoplay=1&amp;autohide=1&amp;wmode=transparent&amp;iv_load_policy=3&amp;branding=0&amp;fs=0&amp;controls=0&amp;mute=1&amp;modestbranding=1&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;playlist=kUrDaxumwpw&amp;widgetid=1"  class="ae-media ae-youtube slider-iframe" width="640" height="360" frameborder="0"></iframe>*/}
                      <YouTube id="sadjad1"  videoId="BULVFNkgrQU" className="" iframeClassName="ae-media ae-youtube slider-iframe "  opts={{
                         height: '100%',
                          width: '100%',
                          playerVars: {
                          controls: 0,
                           rel:0,
                          showinfo:0,
                          modestbrowsing:1,

                        },
                      }}   onReady={_onReady2}  onEnd={_onEnd2} loading={'lazy'}  /> 
                      </div>
                    </SwiperSlide>
                    
                  </Swiper>
                  :
                  <div className="w-full h-96 flex items-center justify-center">
                    {/*<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" /> */}
                  </div>
                  }
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
           )
}

export default VideoSlider;
