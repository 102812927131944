import React, {useState, useEffect, useRef} from "react";


import {APP_URL, APP_NAME ,IMG_URL, amount_format,discount ,machine_condition,API_ENDPOINT,toast_anim,admin_phone} from '../components/config/Common'
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { BiPhone, BiCalendar } from "react-icons/bi";
import { BsQuestionSquare } from "react-icons/bs";

import { HiOutlinePhone , HiOutlineInbox ,HiCalendar} from "react-icons/hi";
import { motion as ms } from 'framer-motion';
import {Links} from '../routes/PathLink'
import { Link } from "react-router-dom";
import Quotebyphone from '../components/productrequest/Quotebyphone';
import QuestionRequest from '../components/productrequest/QuestionRequest';
import ScheduleTestDriver from '../components/productrequest/ScheduleTestDriver';

const ProductCard = ({
   item,
   upcoming
}:props) => {
   const [openquotephnpopup,setPpenquotephnpopup] = useState(false);
   const [openquestionpopup,setOpenQuestionpopup] = useState(false);
   const [openschedulepopup,setOpenSchedulepopup] = useState(false);

   const [record,setRecord] = useState(null);


   const closeModel = () =>{
      setPpenquotephnpopup(false);
   }
   const closeQueModel = () =>{
       setOpenQuestionpopup(false);
   }

   const closeScheduleModel = () =>{
       setOpenSchedulepopup(false);
   }
   return(
         <ms.article 
         layout
         initial={{ y: 10, opacity: 0 }}
         animate={{ y: 0, opacity: 1 }}
         exit={{ y: -10, opacity: 0 }}
         transition={{ duration: 0.5 }}
         className="product-card cart-type-krypton h-full cursor-pointer overflow-hidden rounded border border-border-200 bg-light dark:bg-gray-700 dark:border-none transition-shadow duration-200 hover:shadow-sm">
               <div className="flex flex-col lg:flex-row p-5">
                  <div className="mx-auto w-8/12 lg:w-3/12">
                     <div className="relative border">
                     <Link to={`${Links.PRODUCT}/${item.product_slug}`}>
                     {
                        item.product_image
                        ?
                        <LazyLoadImage src={`${IMG_URL}${item.product_image}`} className="h-40 w-full sm:h-52 object-contain" alt={item.product_name} sizes="100vw" />
                        :
                        <LazyLoadImage src={`${APP_URL}/img/no-image-available.png`} alt={item.product_name} sizes="100vw" />
                     }
                     </Link>

                     {
                     item.availability_status === 'Sold'
                     ?
                     <span class="sft-ribbon-mask">
                        <span class="bg-dark text-light sft-ribbon sft-ribbon-sold">SOLD</span>
                     </span>
                     :null
                     }
                     </div>
                  </div>
                  <div className="lg:w-9/12">
                     <div className="flex flex-wrap pb-4">
                     <div className="w-full py-5 lg:w-8/12 lg:px-6 lg:py-0">
                     <Link to={`${Links.PRODUCT}/${item.product_slug}`}>
                        <h3 className="text-xl text-gray-800 hover:text-accent">{item.product_name}</h3>
                     </Link>
                     </div>
                     <div className="w-full lg:w-4/12">
                        {item.price ?
                        <h3 className="text-xl">{amount_format(item.price)}</h3>
                        :
                        <h3 className="text-xl">Call for Price</h3>
                        }
                     </div>
                     </div>
                   <div className="flex flex-wrap">
                   <div className="w-full py-5 lg:w-8/12 lg:px-6 lg:py-0">
                     <div className="w-full text-sm border-b border-gray pb-4">Availability: <span className="text-sm font-bold">{item.availability_status}</span></div>
                     <div className="w-full py-2 text-sm ">Usage: {machine_condition[item.machine_condition]}</div>
                   </div>
                   <div className="w-full lg:w-4/12">
                      <div className="flex flex-col align-end justify-end">
                       <Link to="#" onClick={()=>{ setPpenquotephnpopup(true) }} className="block w-full" >
                        <button className="flex items-center justify-center w-full border-accent bg-accent border mb-3 px-2 py-2 hover:bg-accent-500 hover:border-accent-500 text-white text-sm transition-all"><HiOutlinePhone size={20} className="mr-2" />Quote By Phone </button>
                        </Link>
                        <Link to={`${Links.PRODUCT}/${item.product_slug}`} className="block w-full mb-3" >
                        <button className="border-gray-400 border px-2 py-2 w-full hover:bg-gray-100 text-sm">View Details</button>
                        </Link>
                         <Link to="#" onClick={()=>{ setOpenQuestionpopup(true); setRecord(item) }} className="block w-full mb-3" >
                           <button className="flex justify-center items-center border-gray-400 border px-2 py-2 w-full hover:bg-gray-100 text-sm">
                              <BsQuestionSquare size={18} className="mr-2" />
                              <span>Questions</span> 
                           </button>
                        </Link>
                        <Link to="#" onClick={()=>{ setOpenSchedulepopup(true); setRecord(item) }} className="block w-full mb-3" >
                           <button className="flex justify-center items-center border-gray-400 border px-2 py-2 w-full hover:bg-gray-100 text-sm"><BiCalendar size={20} className="mr-2" />Schedule Test Drive
                        </button>
                        </Link>
                      </div>
                   </div>
                   </div>  
                   </div>
               </div>
                {openquotephnpopup && <Quotebyphone closeModel={closeModel}/>}
                {openquestionpopup && <QuestionRequest record={record} closeModel={closeQueModel}  /> }  
               {openschedulepopup && <ScheduleTestDriver record={record} closeModel={closeScheduleModel}  /> }

         </ms.article>
   )
}

export default ProductCard;