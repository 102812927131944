import React, {useState, useEffect} from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";

import * as yup from 'yup';

import {API_ENDPOINT, APP_URL, APP_NAME, admin_phone, toast_anim} from '../config/Common'
import ScrollPageTop from '../../routes/ScrollPageTop'
import {Links} from '../../routes/PathLink'
import Seo from '../seo/seo'
import Input from '../../ui/input.jsx'
import TextArea from '../../ui/text-area.tsx'
import Button from '../../ui/Button.tsx'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import "yup-phone-lite";

import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const formSchema = yup.object().shape({
   name: yup.string().required('Name is required'), 
   email: yup.string().required('Email is required'), 
   message: yup.string().required('Message is required'), 
   phone_no:yup.string()
  .phone("US", "Please enter a valid phone number")
  .required("A phone number is required"),
})
let defaultValues = {  
   name: '',
   email:'',
   message:''
};

const ServiceRequest = (props) => {
   console.log("SERVICES Data ",props.service);
   const {service} = props;
   const [loading, setLoading] = useState(false)
   const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(formSchema),
   });

   const [phone_no , setPhone_no] = useState('');
   useEffect(() => {
     // getPageDetails()
   },[]);


   const onSubmit = (values) => {
      console.log('Submit service ', values)
      setLoading(true)
      fetch(`${API_ENDPOINT}/store_servicerequest_form`,{
			method: 'POST',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			},
         body: JSON.stringify(values)
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('mail======',result)
			
         setLoading(false)
         if(result.status === 1){
            defaultValues = {  
               name: '',
               email:'',
               message:''
            };
            reset(defaultValues)	
            toast_anim('Mail sent')		
         }
		})
   }

   return(
      <ScrollPageTop>
      <div className="w-full bg-gray-100">
         <div className="mx-auto flex w-full flex-col py-0 px- md:flex-row ">
           
            <div className="order-1 w-full bg-light p-5 md:order-2 md:mb-0 md:p-8 ">
              
               <h1 className="mb-7 font-body text-xl font-bold text-heading md:text-2xl">
                  Request Information
               </h1>
               
               <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                     <Input                
                        label={`Name`}
                        {...register('name')}                     
                        variant="outline"
                        className="mb-5"
                        error={errors.name?.message}
                        required
                     />
                     <Input                
                        label={`Email`}
                        {...register('email')}                     
                        variant="outline"
                        className="mb-5"
                        error={errors.email?.message}
                        type="email"
                        required
                     />
                  </div>
                  <label className="mb-3 block text-sm font-semibold leading-none text-body-dark dark:text-zinc-400">Phone No. <span class="text-red-500"> *</span></label>
                  <PhoneInput
                    country={'us'}
                    {...register('phone_no')}     
                     className="mb-5 outline-accent"
                     containerClass={` h-12 `}
                     inputClass={` outline-accent  focus:outline-none focus:ring-accent focus:ring-1 h-12 w-full  `}
                     inputStyle={{height:'3rem'}}
                     
                     onChange={phone=>{setValue('phone_no',phone)}}
                  />
                  {errors?.phone_no?.message && errors?.phone_no?.message != '' ?
                  <p class="my-2 text-xs text-red-500 text-start">{errors?.phone_no.message}</p>
                  :
                  (null)
                 }
                  <TextArea
                     label='Message'
                     {...register('message')}                        
                     variant="outline"
                     className="mb-5"
                     error={errors.message?.message}
                     required
                  />
                  <input type="hidden" name="service_id"   {...register('service_id')}       value={service?.id} />
                  <input type="hidden" name="service_title"    {...register('service_title')}       value={service?.title} />
                  <Button loading={loading}>
                     Submit
                  </Button>
                  
               </form>
               
            </div>
         </div>
      </div>
    
      </ScrollPageTop>
   )
}

export default ServiceRequest;