
import {APP_URL, IMG_URL, amount_format,discount ,machine_condition} from '../components/config/Common'

import { BiPhone } from "react-icons/bi";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { HiOutlinePhone , HiOutlineInbox ,HiCalendar} from "react-icons/hi";
import { motion as ms } from 'framer-motion';
import {Links} from '../routes/PathLink'
import { Link } from "react-router-dom";
const ProductCardRelated = ({
   item,
   upcoming
}:props) => {

   return(
     
      <Link to={`${Links.PRODUCT}/${item.product_slug}`}>
         <ms.article 
         layout
         initial={{ y: 10, opacity: 0 }}
         animate={{ y: 0, opacity: 1 }}
         exit={{ y: -10, opacity: 0 }}
         transition={{ duration: 0.5 }}
         className="product-card cart-type-krypton h-full cursor-pointer overflow-hidden rounded border border-border-200 bg-light dark:bg-gray-700 dark:border-none transition-shadow duration-200 hover:shadow-sm">
            <div className="relative flex h-48 w-auto items-center justify-center sm:h-64">      
              
               {
                  item.product_image
                  ?
                  <LazyLoadImage src={`${IMG_URL}${item.product_image}`} className="h-48 w-full sm:h-64 object-cover" alt={item.product_name} sizes="100vw" />
                  :
                  <LazyLoadImage src={`${APP_URL}/img/no-image-available.png`} alt={item.product_name} sizes="100vw" />
               }
               
               {
               discount(item.price, item.sale_price) > 0 ?
               <div class="absolute top-3 rounded-full bg-accent px-1.5 text-xs font-semibold leading-6 text-light ltr:right-3 rtl:left-3 sm:px-2 md:top-4 md:px-2.5 ltr:md:right-4 rtl:md:left-4">{discount(item.price, item.sale_price)}%</div>
               :null
               }
               
            </div>
            <header className="relative p-3 md:p-5 md:py-6">
               <h3 role="button" className="">{item.product_name}</h3>
               
            </header>
         </ms.article>
      </Link>
      
   )
}

export default ProductCardRelated;