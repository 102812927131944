import React, {useState, useEffect} from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";

import * as yup from 'yup';

import {API_ENDPOINT, APP_URL, APP_NAME, admin_phone, toast_anim} from '../config/Common'
import ScrollPageTop from '../../routes/ScrollPageTop'
import {Links} from '../../routes/PathLink'
import Seo from '../seo/seo'
import Input from '../../ui/input.jsx'
import TextArea from '../../ui/text-area.tsx'
import Button from '../../ui/Button.tsx'


import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const formSchema = yup.object().shape({
   name: yup.string().required('Name is required'), 
   email: yup.string().required('Email is required'), 
   subject: yup.string().required('Subject is required'), 
   description: yup.string().required('Description is required'), 
})
let defaultValues = {  
   name: '',
   email:'',
   subject:'',
   description:''
};

const Contact = (props) => {

   const [loading, setLoading] = useState(false)
   const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(formSchema),
   });


   const [pagecontent, setPagecontent] = useState(null);
   const [pagetitle, setPageTitle] = useState(null);

   useEffect(() => {
      getPageDetails()
   },[]);

    const getPageDetails = () => {
      //setLoading(true)
      fetch(`${API_ENDPOINT}/page_by_slug/contact`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         //console.log('slider======',result)
         //setLoading(false)
         setPagecontent(result?.data?.content);
         setPageTitle(result?.data?.title);
         //setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.title)
         //setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.content)
      })
   }

   const onSubmit = (values) => {
      //console.log('contact', values)
      setLoading(true)
      fetch(`${API_ENDPOINT}/store_contact_form`,{
			method: 'POST',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			},
         body: JSON.stringify(values)
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('mail======',result)
			
         setLoading(false)
         if(result.status === 1){
            defaultValues = {  
               name: '',
               email:'',
               subject:'',
               description:''
            };
            reset(defaultValues)	
            toast_anim('Mail sent')		
         }
		})
   }

   return(
      <ScrollPageTop>
      <Seo 
         title={`Contact - ${APP_NAME}`}
         desc="Contact ctmri"
         url={`${APP_URL}${Links.CONTACT}`}
         image={`./img/home-bg.jpg`}
      />
      <Header />
      <div className="w-full bg-gray-100">
         <div className="mx-auto flex w-full max-w-7xl flex-col py-10 px-5 md:flex-row xl:py-14 xl:px-8 2xl:px-14">
            <div className="order-2 w-full shrink-0 bg-light p-5 md:order-1 md:w-72 lg:w-96">
               <div className="mb-8 flex w-full items-center justify-center overflow-hidden">
                  <img alt="Contact" src="./img/contact.svg" decoding="async"    data-img="intrinsic" className="h-auto w-full" />
               </div>
               <div className="mb-8 flex flex-col">
                  <span className="mb-3 font-semibold text-heading">
                     Address
                  </span>
                  <span className="text-sm text-body">
                  199 1/2 East Main St, West Jefferon, Ohio. 43162.

                  </span>
               </div>   
               <div className="mb-8 flex flex-col">
                  <span className="mb-3 font-semibold text-heading">
                     Phone
                  </span>
                  <span className="text-sm text-body">
                  <a href={`tel:614-642-1030`}>614-642-1030</a>
                  </span>
               </div> 
               <span className="mb-1 text-sm text-heading dark:text-zinc-300">
                         Mon - Fri: 9am - 5pm<br />
                    </span>
                    <span className="text-sm text-heading dark:text-zinc-300">
                        Saturday: 9am - 12pm<br />
                    </span>
                     <span className="text-sm text-heading dark:text-zinc-300">
                       Sunday: Closed
                    </span>              
            </div>
            <div className="order-1 mb-8 w-full bg-light p-5 md:order-2 md:mb-0 md:p-8 ltr:md:ml-7 rtl:md:mr-7 ltr:lg:ml-9 rtl:lg:mr-9">
               <h2 className="font-light text-5xl text-body mb-2">{pagetitle}</h2>
                     <p className="text-body mb-1" dangerouslySetInnerHTML={{__html:pagecontent}}></p>
               <h1 className="mb-7 font-body text-xl font-bold text-heading md:text-2xl">
               Questions, Comments, Or Concerns?

               </h1>
               
               <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                     <Input                
                        label={`Name`}
                        {...register('name')}                     
                        variant="outline"
                        className="mb-5"
                        error={errors.name?.message}
                        required
                     />
                     <Input                
                        label={`Email`}
                        {...register('email')}                     
                        variant="outline"
                        className="mb-5"
                        error={errors.email?.message}
                        type="email"
                        required
                     />
                  </div>
                  <Input                
                     label={`Subject`}
                     {...register('subject')}                     
                     variant="outline"
                     className="mb-5"
                     error={errors.subject?.message}
                     required
                  />
                  <TextArea
                     label='Description'
                     {...register('description')}                        
                     variant="outline"
                     className="mb-5"
                     error={errors.description?.message}
                     required
                  />
                  <Button loading={loading}>
                     Submit
                  </Button>
                  
               </form>
               
            </div>
         </div>
      </div>
      <div>
            <iframe src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=199 east main st, west Jefferson, ohio&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" style={{width:'100%',height:400}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
      <Footer />
      </ScrollPageTop>
   )
}

export default Contact;