import React, {useState, useEffect, useRef} from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import {API_ENDPOINT, APP_URL, APP_NAME, IMG_URL, replaceWithBr, LIMIT, showRating} from '../config/Common'
import ScrollPageTop from '../../routes/ScrollPageTop'
import {Links} from '../../routes/PathLink'
import Seo from '../seo/seo'
import {Loading} from '../../custom-icons'
import cn from 'classnames';
import InfiniteScroll from "react-infinite-scroll-component";
import NoRecords from '../NoRecords'



const ShowTestimonials = () => {
   const [loading, setLoading] = useState(true)
   const [details, setDetails] = useState(null)
   const [totalRec, setTotalRec] = useState(0)
   const [page, setPage] = useState(1)
   const [hasMore, setHasMore] = useState(true)
   const [desc, setDesc] = useState(null)
   
   const [pagecontent, setPagecontent] = useState(null);
   const [pagetitle, setPageTitle] = useState(null);

   useEffect(() => {
      getTestimonials(1)
   },[])


      useEffect(() => {
      getPageDetails()
   },[]);

    const getPageDetails = () => {
      //setLoading(true)
      fetch(`${API_ENDPOINT}/page_by_slug/testimonials`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         //console.log('slider======',result)
         //setLoading(false)
         setPagecontent(result?.data?.content);
         setPageTitle(result?.data?.title);
         //setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.title)
         //setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.content)
      })
   }

   
   const getTestimonials = (pg) => {
      fetch(`${API_ENDPOINT}/get_testimonials?page=${pg}&limit=${LIMIT}`,{
         method: 'GET',
         headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('testimonials=====',result)
         setDetails(result.data)
         setLoading(false)
         if(pg === 1){
            setDetails(result.data)
         }else{
            setDetails([...details, ...result.data])
         }
         let nextPage = page + 1
         setPage(nextPage)

         if(result.data.length < LIMIT){
            setHasMore(false)
         }else{
            setHasMore(true)
         }
         setTotalRec(result.total)
      })
   }

   const fetchData = () => {
      //console.log('page==',page)
      getTestimonials(page)
      
	}

   const showQuote = (text) => {
      return(
         <p>{text}</p>
      )
   }
   
   return(
      <ScrollPageTop>
         <Seo 
            title={`Testimonials - ${APP_NAME}`}
            desc="Testimonials ctmri"
            url={`${APP_URL}${Links.TESTIMONIALS}`}
            image={`./img/home-bg.jpg`}
         />

         <Header />
         {
         loading ?
         <div className="w-full h-96 flex items-center justify-center">
            <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
         </div>
         :
         <section className="mx-auto w-full max-w-1920 py-8 px-4 lg:py-10 lg:px-8 xl:py-14 xl:px-16 2xl:px-20">
            {
            details &&
            <>

                  
                  
            <h1 className="mb-4 text-xl font-light text-center text-heading sm:mb-5 sm:text-3xl md:text-5xl 2xl:mb-7 2xl:text-4xl text-center">
            What Our Clients Say About Us
            </h1>
            <p className="text-body mb-5" dangerouslySetInnerHTML={{__html:pagecontent}}></p>
            <InfiniteScroll
               dataLength={details.length} //This is important field to render the next data
               next={fetchData}
               hasMore={hasMore}
               loader={
                  <div className="h-20 mt-5 w-full md:max-w-[680px] mx-auto"><Loading className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />Loading...</div>
               }
               scrollThreshold={0.7}
            >
            {
            details && details.map((val, key) => (
            
            <div key={`key-${val.testimonial_id}`} className={cn("w-full md:max-w-[680px] shrink-0 p-5 mx-auto mb-10 rounded-3xl",{
               'bg-green-200 lg:mr-40':key % 2 === 0,
               'bg-yellow-200 lg:ml-40':key % 2 !== 0,
            })}>
               <div className="grid grid-cols-1 gap-6">
                  {
                     val.image &&
                  <div className="rounded-full overflow-hidden w-40 h-40 mx-auto">
                     <img src={`${IMG_URL}${val.image}`} alt={val.name} className="w-40 h-40 object-cover" />
                  </div>
                  }
                  <div className="text-center font-semibold text-2xl text-gray-900 dark:text-white capitalize">
                     {val.name}
                  </div>
                  {val.testimony && <blockquote className="text-center text-xl text-gray-900 dark:text-white" dangerouslySetInnerHTML={{__html:replaceWithBr(val.testimony)}} />}

                  <div className="flex items-center justify-center">{showRating(val.rating)}</div>
                  
               </div>
            </div>
            ))
            }
            </InfiniteScroll>
            </>
            }
         </section>
         }
         <Footer />
      </ScrollPageTop>
   )
}

export default ShowTestimonials