import React, {useState, useEffect, useRef} from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Collapse from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import ScrollPageTop from '../../routes/ScrollPageTop'

import { Link , useParams, useNavigate, createSearchParams } from "react-router-dom";
import {API_ENDPOINT, APP_URL, APP_NAME, IMG_URL, LIMIT} from '../config/Common'
import {Bed, Table, Sofa, Chair, Bucket, XrayIcon, MriIcon, CtScanIcon, Loading} from "../../custom-icons";
import { BiRupee } from "react-icons/bi";
import { motion as ms } from 'framer-motion';
import motion from '../../_utils/motionUtil.ts'
import { BiDownArrow } from "react-icons/bi";
import classNames from 'classnames';

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import ServiceCard from '../../cards/ServiceCard'
import {Links} from '../../routes/PathLink'
import { useSearch } from '../../ui/search/search.context.tsx';
import InfiniteScroll from "react-infinite-scroll-component";
import NoRecords from '../NoRecords'
import Seo from '../seo/seo'
import {isMobile} from "react-device-detect"
import { Range, getTrackBackground } from 'react-range';
import {CloseIcon} from '../../custom-icons'
import Button from '../../ui/Button.tsx'

const STEP = 100;
const MIN = 1000;
const MAX = 500000;
const rtl = false

const Services = () => {
   const [process, setProcess] = useState(false)
   const [products, setProducts] = useState(null)
   const [totalRec, setTotalRec] = useState(0)
   const [page, setPage] = useState(1)
   const [hasMore, setHasMore] = useState(true)

   const myRef = useRef(null)

    const [metaData, setMetaData] = useState({
      url: `${APP_URL}${Links.SERVICES}`,
      title:  `Services - ${APP_NAME}`,
      description:'Services'
   })

    useEffect(() => {
    
         getProducts(1)
     
   },[])

    const getProducts = (pg) => {

      let paginate = `page=${pg}&limit=${LIMIT}`;
     
      fetch(`${API_ENDPOINT}/services?${paginate}`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('cat products======',result)
         //setProducts(result.data)
         if(pg === 1){
            setProducts(result.data)
         }else{
            setProducts([...products, ...result.data])
         }
         let nextPage = page + 1;
         setPage(nextPage)
         setTotalRec(result.total)

         if(result.data.length < LIMIT){
            setHasMore(false)
         }else{
            setHasMore(true)
         }

         setMetaData({
            url: `${APP_URL}${Links.CATEGORY}`,
            title:  `Services - ${APP_NAME}`,
            description:'Services'
         })
         
         if(isMobile){
            myRef?.current?.scrollIntoView( { behavior: 'smooth', block: 'start' } )
         }
         setProcess(true)
         
         /* let p = document.getElementById('product-list')
         p.scrollIntoView( { behavior: 'smooth', block: 'start' } ); */
      })
   }

   const fetchData = () => {
      console.log('page==',page)
      getProducts(page);
   }

   return(
      <ScrollPageTop>
      <Seo 
         title={metaData.title}
         desc={metaData.description}
         url={metaData.url}
         image={`./img/home-bg.jpg`}
      />
      <Header />
      
      <div className="mx-auto flex w-full flex-col py-8 px-0 lg-px-5 md:flex-row xl:px-6">
            <main ref={myRef} id="product-list" className="block w-full lg:mt-6 xl:overflow-hidden ltr:xl:pl-0">
            {
            process
            ?
               <div name="grid" className="px-4 xl:px-0">
                  <div className="w-full">
                  {
                  products && totalRec > 0 ?
                  <InfiniteScroll
                     dataLength={products.length} //This is important field to render the next data
                     next={fetchData}
                     hasMore={hasMore}
                     loader={
                        <h4 className="h-20 mt-5"><Loading className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />Loading...</h4>
                     }
                     scrollThreshold={0.7}
                     >
                     <div layout className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-[repeat(auto-fill,minmax(350px,1fr))] gap-10 mx-2 my-4">
                     {
                        products && products.map((item, key) => (
                           <ServiceCard item={item} key={`product-${item.p_id}`} />
                        ))
                     }                     
                     </div>      
                  </InfiniteScroll>               
                  :
                     <NoRecords message="Sorry, No Service Found :(" />
                  
                  }
                     {/* <div className="mt-8 flex justify-center lg:mt-12"><button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 h-11 text-sm font-semibold md:text-base">Load More</button></div> */}
                  </div>
               </div>
               :
                  <div className="w-full h-96 flex items-center justify-center">
                     <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
                  </div>
               }
            </main>
      </div>
      <Footer />
      </ScrollPageTop>
   )
}

export default Services