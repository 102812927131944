import React, {useState, useEffect, useRef} from "react";

import { Link , useParams, useNavigate } from "react-router-dom";
import {
	API_ENDPOINT, 
	replaceWithBr, 
	toast_anim, 
	toast_anim_error, 
	APP_URL, 
	APP_NAME,
	IMG_URL, 
	amount_format,
	discount,
	machine_condition
} from './config/Common'
import classNames from 'classnames';
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Counter from '../layouts/Counter';


// import Swiper core and required modules
import { Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';

import {HeartOutlineIcon, HeartFillIcon, ChevronLeft, ChevronRight, Loading} from "../custom-icons";

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'

import ScrollPageTop from '../routes/ScrollPageTop'

import RelatedProducts from './RelatedProducts'

import {Links} from '../routes/PathLink'
import SavePrice from '../ui/SavePrice'

import Button from '../ui/Button.tsx'
import Zoom from 'react-img-zoom'
import Seo from './seo/seo'

// product gallery breakpoints
const galleryCarouselBreakpoints = {
	320: {
	  slidesPerView: 2,
	},
	480: {
	  slidesPerView: 3,
	},
	640: {
	  slidesPerView: 3,
	},
	800: {
	  slidesPerView: 4,
	},
  };


const ProductDetails = () => {	
	const navigate = useNavigate();
	const [productsImg, setProductsImg] = useState([])	
	const [thumbsSwiper, setThumbsSwiper] = useState(null)
	const [product, setProduct] = useState(null)	
	const [descExpand, setDescExpand] = useState(true)	
	
	const [inWishlist, setInWishlist] = useState(false)	
	
	const [quantity, setQuantity] = useState(0)
	const [invoice] = useState(localStorage.getItem('rand_num'))
	const prevRef = useRef(null);
	const nextRef = useRef(null);
	const {slug} = useParams()
	
	const [metaTitle, setMetaTitle] = useState('')
	const [metaDesc, setMetaDesc] = useState('')
	
	const outOfStock = false

	useEffect(() => {
		getProductDetail(slug)
		getProductsImg(slug)
		setTimeout(() => {
			window.scrollTo({
			  top: 0,
			  left: 0,
			  behavior: "smooth"
			});
		 }, 10);
	},[slug])

	useEffect(() => {
		updateViewCount(slug)
	},[])

	

	const updateViewCount = (slug) => {
		fetch(`${API_ENDPOINT}/update_view_count/${slug}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('view count======',result.data)
		})
	}

	
	const getProductDetail = (slug) => {
		//console.log(user_id)

		fetch(`${API_ENDPOINT}/get_products_slug_front/${slug}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('products======',result.data)
			if(result.status === 1){
				setProduct(result.data)
				setInWishlist(result.inWishlist === 0 ? false : true)
				//itemInCart(result.data.p_id)
				//checkItemInCart(result.data.p_id)
				setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.product_name)
				setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.description)
			}else{
				navigate(Links.HOME);
			}
		})
	}

	const getProductsImg = (slug) => {

		fetch(`${API_ENDPOINT}/get_products_slug_front/image/${slug}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('products img======',result)
			setProductsImg(result.data)
		})
	
	}

	
	const setThumbSwiper = (swiper) => {
		//console.log(swiper)
		setThumbsSwiper(swiper)
		
	}



	/* 
	* call this fun from Header.js
	* when user login show wishlist icon
	*/
	const rerenderParentCallback = () => {		
		getProductDetail(slug)		
	}

	/* const descExpand = () => {
		setDescExpand(!descExpand)
	} */

	
   
  
	return (
		<>
		
		<Seo 
         title={`${metaTitle} - ${APP_NAME}`}
         desc={metaDesc && metaDesc.substring(0, 200)}
         url={`${APP_URL}${Links.PRODUCT}/${slug}`}
         image={productsImg && `${IMG_URL}${productsImg[0]}`}
      />
		<Header rerenderParentCallback={rerenderParentCallback} />
		{
			product ?
			<div className="min-h-screen bg-light">
					<article className="rounded-lg bg-light">
				<div className="flex flex-col border-b border-border-200 border-opacity-70 md:flex-row">
					<div className="p-6 pt-10 md:w-1/2">
						<div className="mb-8 flex items-center justify-between lg:mb-10">
							{/* <button className="inline-flex items-center justify-center text-accent font-semibold transition-colors hover:text-accent-hover focus:text-accent-hover focus:outline-none">
								<BackArrowIcon className="w-5 h-5 ltr:mr-2 rtl:ml-2" />
								Back
							</button> */}
							{
							discount(product.price, product.sale_price) > 0 ?	
							<div className="rounded-full bg-accent px-3 text-xs font-semibold leading-6 text-light ltr:ml-auto rtl:mr-auto">
								{discount(product.price, product.sale_price)}%
							</div>
							:null
							}
						</div>
						<div className="product-gallery h-full">
							<div className="relative">
								<Swiper
								// install Swiper modules
								id="productGallery"
								modules={[Navigation, Thumbs]}
								spaceBetween={0}
								slidesPerView={1}
								thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
								navigation={{
									prevEl: prevRef.current, // Assert non-null
									nextEl: nextRef.current, // Assert non-null
								}}
								>
								{
									productsImg ? productsImg.map((item, index) => (
									<SwiperSlide key={`normal-${index}`} className="flex items-center justify-center selection:bg-transparent">
										{/* <Zoom>
											<img src={`${IMG_URL}${item}`} width={450} height={450} alt={index} className="cursor-zoom-in" />
										</Zoom> */}
										<Zoom
											img={`${IMG_URL}${item}`}
											zoomScale={3}
											width={450}
											height={350}
											/>
									</SwiperSlide>
									))
									:
									<SwiperSlide className="flex items-center justify-center selection:bg-transparent">
										<img src={`${APP_URL}/img/no-image-available.png`} width={200} height={200} alt="" />
									</SwiperSlide>
								}								
								</Swiper>
								<div
								ref={prevRef}
								className="product-gallery-prev absolute top-2/4 z-10 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-border-200 border-opacity-70 bg-light text-heading shadow-xl transition-all duration-200 hover:bg-gray-100 ltr:-left-4 rtl:-right-4 md:-mt-5 md:h-9 md:w-9 ltr:md:-left-5 rtl:md:-right-5"
								>
									<ChevronLeft className="h-4 w-4" />
								</div>
								<div
								ref={nextRef}
								className="product-gallery-next absolute top-2/4 z-10 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-border-200 border-opacity-70 bg-light text-heading shadow-xl transition-all duration-200 hover:bg-gray-100 ltr:-right-4 rtl:-left-4 md:-mt-5 md:h-9 md:w-9 ltr:md:-right-5 rtl:md:-left-5"
								>
									<ChevronRight className="h-4 w-4" />
								</div>
							</div>
							<div className='relative mx-auto mt-5 max-w-md lg:mt-8 lg:pb-2'>
								<Swiper
									id="productGalleryThumbs"
									spaceBetween={20}
									watchSlidesProgress={true}
									freeMode={true}
									observer={true}
									observeParents={true}
									breakpoints={galleryCarouselBreakpoints}
									onSwiper={setThumbSwiper}
								>
								{
									productsImg && productsImg.map((item, index) => (
										<SwiperSlide key={`thumb-${index}`} className="flex cursor-pointer items-center justify-center overflow-hidden rounded border border-border-200 border-opacity-75 hover:opacity-75">
											<img src={`${IMG_URL}${item}`} width={80} height={80} alt={index}  />
										</SwiperSlide>
									))
								}
								</Swiper>
							</div>
						</div>

					</div>

					<div className="flex flex-col items-start p-5 pt-10 md:w-1/2 lg:p-14 xl:p-16">
						<div className="w-full">
							<div className="flex w-full items-start justify-between space-x-8 rtl:space-x-reverse">
								<h1 className="text-lg font-semibold tracking-tight text-heading md:text-xl xl:text-2xl capitalize">{product.product_name}</h1>
								
							</div>
							{
							product.sku && 							
							<div class="flex items-center justify-between mt-2"><span class="block text-[16px] font-normal text-body">SKU: {product.sku}</span></div>
							}
							{
							product.description && 
							<div className="mt-4 text-[16px] leading-8 text-body md:mt-4">
								
								
								{descExpand ? 
									product.description.substring(0, 200) 
								: 
									
									<span dangerouslySetInnerHTML={{__html:replaceWithBr(product.description)}} />
								} 

								{
									product.description.length >= 200 && 
									<span className="cursor-pointer text-orange-500 hover:text-accent" onClick={() => setDescExpand(!descExpand)}> [...{descExpand ? `more` : 'less'}] </span>
								}
							</div>
												
							}
							
							<span className="my-5 flex items-center">
								{
								product.sale_price ?
								<ins className="text-2xl font-semibold text-accent no-underline md:text-3xl flex items-center">
									{amount_format(product.sale_price)}
								</ins>
								:
								<ins className="text-2xl font-semibold text-accent no-underline md:text-3xl flex items-center">
									{product.price == null || product.price == 0?
									null
									:
									(<>{amount_format(product.price)}</>)
									}
								</ins>
								}
								
								{
								product.sale_price && Number(product.sale_price) < Number(product.price) ?
								<del className="text-sm font-normal text-muted ltr:ml-2 rtl:mr-2 md:text-base flex items-center">
									{amount_format(product.price)}										
								</del>
								:null
								}
							</span>
							{
							product.sale_price > 0 && product.price > 0 ? 
							<SavePrice max={product.price} min={product.sale_price} />
							:null
							}

							{
								product.warranty_price && 
								<span className="text-xl font-semibold text-accent no-underline flex items-center">
									{amount_format(product.warranty_price)} <span className="text-sm ml-2 text-body font-normal">(Price with warranty)</span>
								</span>
							}
							
						</div>
						{
						product.categories &&
						<div className="w-full mt-4 md:mt-6 pt-4 md:pt-6 flex flex-row items-start border-t border-border-200 border-opacity-60">
							<span className="text-sm font-semibold text-heading capitalize ltr:mr-6 rtl:ml-6 py-1">Categories</span>
							<div className="flex flex-row flex-wrap">
								{
								product.categories.map((val, key) => (
								<Link key={key} to={!val.parent ? `${Links.CATEGORY}/${val.slug}` : `${Links.CATEGORY}/${val.parent.slug}/${val.slug}`} className="text-sm text-heading tracking-wider whitespace-nowrap py-1 px-2.5 bg-transparent border border-border-200 rounded ltr:mr-2 rtl:ml-2 mb-2 transition-colors hover:border-accent hover:text-accent focus:outline-none focus:bg-opacity-100">{val.cat_name}</Link>
								))
								}
							</div>
							
						</div>
						}
						
					</div>
				</div>
				

				<div name="details" className="border-b border-border-200 border-opacity-70 px-5 py-4 lg:px-16 lg:py-14"><h2 className="mb-4 text-lg font-semibold tracking-tight text-heading md:mb-6">Specification</h2>
					<div className="font-normal text-body capitalize">
					{
					product.sku && 
						<div className="flex items-center">
							<div className="w-52 py-2">SKU</div>
							<div className="px-2">:</div>
							<div>{product.sku}</div>
						</div> 
					}
					{
					product.brand && 
						<div className="flex items-center">
							<div className="w-52 py-2">Manufacturer</div>
							<div className="px-2">:</div>
							<div>{product.brand.name}</div>
						</div> 
					}
					{
					product.model && 
						<div className="flex items-center">
							<div className="w-52 py-2">model</div>
							<div className="px-2">:</div>
							<div>{product.model.name}</div>
						</div> 
					}
					{
					product.tube_magnet_year && 							
						<div className="flex items-center">
							<div className="w-52 py-2">Tube/Magnet Year</div>
							<div className="px-2">:</div>
							<div>{product.tube_magnet_year}</div>
						</div> 
					}
					{
					product.tube_count && 							
					
						<div className="flex items-center">
							<div className="w-52 py-2">Tube count/Helium level</div>
							<div className="px-2">:</div>
							<div>{product.tube_count}</div>
						</div> 
					}
					{
					product.yom && 					
						<div className="flex items-center">
							<div className="w-52 py-2">YOM (Year of Manufacture)</div>
							<div className="px-2">:</div>
							<div>{product.yom}</div>
						</div> 
					}
					{
					product.delivery_period && 							
						<div className="flex items-center">
							<div className="w-52 py-2">Availability period</div>
							<div className="px-2">:</div>
							<div>{product.delivery_period}</div>
						</div> 
					}
					{
					product.machine_condition && 
						<div className="flex items-center">
							<div className="w-52 py-2">Condition</div>
							<div className="px-2">:</div>
							<div>{machine_condition[product.machine_condition]}</div>
						</div> 
					}

					{
					product.accessories && 
						<div className="flex items-center">
							<div className="w-52 py-2">Accessories</div>
							<div className="px-2">:</div>
							<div>{product.accessories}</div>
						</div> 
					}
					
					
				</div>
				</div>
			</article>
			
				{product.p_id > 0 && 
					<RelatedProducts 
						currentProduct={product.p_id} 
						category={product.cat_id}	
					/>
				}
			
			</div>
			:
			<div className="w-full h-96 flex items-center justify-center">
				<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
			</div>
			
		}
		<Footer />
		</>
		);
	
};

export default ProductDetails