import React from "react";
import Logo from './logo';
import { Link } from "react-router-dom";
import {SendIcon} from "../custom-icons/SendIcon";
import {APP_URL} from '../components/config/Common'
import {Links} from '../routes/PathLink'

import { AiFillTwitterCircle, AiFillInstagram, AiFillFacebook, AiFillLinkedin } from 'react-icons/ai';



const Footer = () => {

    return (
        <div className="flex w-full flex-col mt-20 border-gray-800 bg-[#222] lg:border-b-8  dark:bg-gray-700">
           
            <div className="grid w-full grid-cols-[repeat(auto-fill,minmax(260px,1fr))] gap-6 pt-16 md:grid-cols-3 xl:grid-cols-[repeat(auto-fit,minmax(220px,1fr))] xl:gap-8 2xl:gap-2 2xl:grid-cols-4 px-5 md:px-8 lg:px-8 justify-center m-auto">
                <div className="flex flex-col">
                    <div className="mb-[2px] flex h-16 items-start">
                        <Logo />
                    </div>

                    <address className="mb-7 text-sm not-italic  dark:text-zinc-300 capitalize mt-5 text-white">
                    199 1/2 East Main St, West Jefferon, Ohio. 43162.

                    </address>
                    <span className="text-sm text-white dark:text-zinc-300">
                         Mon - Fri: 9am - 5pm
                    </span>
                    <span className="my-2 text-sm  text-white dark:text-zinc-300">
                        Saturday: 9am - 12pm
                    </span>
                     <span className="text-sm  text-white dark:text-zinc-300">
                       Sunday: Closed
                    </span>
                </div>

                <div className="flex flex-col">
                <h3 className="mt-3 mb-7 text-2xl font-normal  dark:text-zinc-300 text-white">
                    Services
                    </h3>
                    <ul className="space-y-3">
                        <li>
                            <Link to={Links.CONTACT} className="text-sm  transition-colors text-white hover:text-accent dark:text-zinc-300 dark:hover:text-orange-500">Contact</Link>
                        </li>
                        <li>
                            <Link to={Links.SERVICES} className="text-sm  transition-colors hover:text-accent dark:text-zinc-300 dark:hover:text-orange-500 text-white">Services</Link>
                        </li>
                         <li>
                            <Link to={Links.CATEGORY} className="text-sm  transition-colors hover:text-accent dark:text-zinc-300 dark:hover:text-orange-500 text-white">Equipment For Sale</Link>
                        </li>
                         <li>
                            <Link to={Links.PARTSLOOKUP} className="text-sm  transition-colors hover:text-accent dark:text-zinc-300 dark:hover:text-orange-500 text-white">Parts Lookup</Link>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-col">
                <h3 className="mt-3 mb-7 text-2xl font-normal  dark:text-zinc-300 text-white">
                    Browse
                    </h3>
                    <ul className="space-y-3">
                        <li>
                            <Link to={Links.ABOUT} className="text-sm  transition-colors hover:text-accent dark:text-zinc-300 dark:hover:text-orange-500 text-white">About</Link>
                        </li>
                        <li>
                            <Link to={Links.TERMS} className="text-sm  transition-colors hover:text-accent dark:text-zinc-300 dark:hover:text-orange-500 text-white">Terms and conditions</Link>
                        </li>
                        <li>
                            <Link to={Links.PRIVACY} className="text-sm  transition-colors hover:text-accent dark:text-zinc-300 dark:hover:text-orange-500 text-white">Privacy Policy</Link>
                        </li>
                        <li>
                            <Link to={Links.TESTIMONIALS} className="text-sm  transition-colors hover:text-accent dark:text-zinc-300 dark:hover:text-orange-500 text-white">Testimonials</Link>
                        </li>
                        <li>
                            <Link to={Links.BLOG} target="_blank" className="text-sm  transition-colors hover:text-accent dark:text-zinc-300 dark:hover:text-orange-500 text-white">Blog</Link>
                        </li>
                    </ul>
                </div>

                <div className="flex flex-col">
                   <h3 className="mt-3 mb-7 text-2xl font-normal  dark:text-zinc-300 text-white">
                    Subscribe Now
                    </h3>
                    <p className="mb-7 text-sm  dark:text-zinc-300 text-white">Subscribe your email for newsletter and featured news based on your interest</p>
                    <div className="flex flex-col">
                        <div className="relative w-full rounded border border-gray-200 bg-gray-50 ltr:pr-11 rtl:pl-11">
                            <input
                                type="email"
                                id="email_subscribe"                                
                                placeholder={'Write your email here'}
                                className="h-14 w-full border-0 bg-transparent text-sm text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5"
                            />
                            <button className="absolute top-1/2 -mt-2 ltr:right-3 rtl:left-3"><SendIcon className="text-gray-500 transition-colors hover:text-accent" /></button>
                        </div>
                    </div>

                    <h3 className="mt-5 mb-3 text-2xl font-normal  dark:text-zinc-300 text-white">
                    Follow us on
                    </h3>
                    <div className="flex">
                        <a href="https://facebook.com" rel="noreferrer" target="_blank" className="mr-2 text-3xl text-white hover:text-[#1877f2]"><AiFillFacebook /></a>
                        <a href="https://twitter.com" rel="noreferrer" target="_blank" className="mr-2 text-3xl text-white hover:text-[#1da1f2]"><AiFillTwitterCircle /></a>
                        <a href="https://instagram.com" rel="noreferrer" target="_blank" className="mr-2 text-3xl text-white hover:text-[#c13584]"><AiFillInstagram /></a>
                        <a href="https://www.linkedin.com" rel="noreferrer" target="_blank" className="mr-2 text-3xl text-white hover:text-[#0A66C2]"><AiFillLinkedin /></a> 
                    </div>
                </div>
            </div>
            <div className="mt-8 flex w-full flex-col items-center border-t border-gray-700 pt-8 pb-12 lg:flex-row lg:justify-between lg:border-t px-5 md:px-10 lg:px-[50px] xl:px-16">
                <span className="order-2 text-sm  lg:order-1 dark:text-zinc-300 text-white">
                &copy; Copyright {new Date().getFullYear()}{' '}
                <Link
                    className="font-bold  transition-colors hover:text-accent dark:text-zinc-100 dark:hover:text-accent text-white"
                    to='/'
                >
                   PowerTripOhio - Dixie Chopper Dealer, Parts distributor, and Commercial Mower Service
                </Link>{' '}
                All rights reserved
                </span>
            </div>
        </div>
    )

}

export default Footer;