import React, {useState, useEffect} from "react";
import {API_ENDPOINT} from './config/Common'
import cn from 'classnames';

import ProductCardRelated from '../cards/ProductCardRelated'

const RelatedProducts = ({
   currentProduct, category
} :props) => {
   const [products, setProducts] = useState(null)
   useEffect(() => {
      const getRelatedProducts = () => {
      //console.log(currentProduct, category)
      fetch(`${API_ENDPOINT}/get_related_product/${currentProduct}/${category}`,{
         method: 'GET',
         headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         //console.log('insert data qty=====',result)
         setProducts(result.data)
         
      })
   }
      getRelatedProducts()
   },[currentProduct, category])
   
   

   return(
      <>
         {
         products && 
         <div className="p-5 lg:p-14 xl:p-16">
            <h2 className="text-lg text-heading tracking-tight font-semibold mb-6">
            You might be interested in
            </h2>
            <div
            className={cn(
            'grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4 lg:grid-cols-4 2xl:grid-cols-5 !gap-3')}
            >
            {
               products.map((item, key) => (
                  <ProductCardRelated item={item} key={`product-${item.p_id}`} />
               ))
            }
            </div>
         </div>
         }
      </>
   )
}

export default RelatedProducts;