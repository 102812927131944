import React, {useState, useEffect, useRef} from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Collapse from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import ScrollPageTop from '../../routes/ScrollPageTop'

import { Link , useParams, useNavigate, createSearchParams ,useSearchParams } from "react-router-dom";
import {API_ENDPOINT, APP_URL, APP_NAME, IMG_URL, LIMIT} from '../config/Common'
import {Bed, Table, Sofa, Chair, Bucket, XrayIcon, MriIcon, CtScanIcon, Loading} from "../../custom-icons";
import { BiRupee } from "react-icons/bi";
import { motion as ms } from 'framer-motion';
import motion from '../../_utils/motionUtil.ts'
import { BiDownArrow } from "react-icons/bi";
import classNames from 'classnames';

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import ServiceCard from '../../cards/ServiceCard'
import {Links} from '../../routes/PathLink'
import { useSearch } from '../../ui/search/search.context.tsx';
import InfiniteScroll from "react-infinite-scroll-component";
import NoRecords from '../NoRecords'
import Seo from '../seo/seo'
import {isMobile} from "react-device-detect"
import { Range, getTrackBackground } from 'react-range';
import {CloseIcon} from '../../custom-icons'
import Button from '../../ui/Button.tsx'
import ServiceRequest from './service-request';

import { Tab } from '@headlessui/react'


const STEP = 100;
const MIN = 1000;
const MAX = 500000;
const rtl = false


const ServiceDetails = () => {
   const [process, setProcess] = useState(false)
   const [service, setService] = useState(null) 
   const [metaTitle, setMetaTitle] = useState('')
   const [metaDesc, setMetaDesc] = useState('')
   const {slug} = useParams()
   const navigate = useNavigate();
   const [opentab, setOpentab] = useState(0) 
     const [queryParameters] = useSearchParams()
     console.log("url param ",queryParameters);
     let parfor = '';
     if(queryParameters){
      parfor = queryParameters.get("cur");
       console.log("url param ",parfor);
     }

   useEffect(() => {
      if(parfor == 'requestinfo'){
         setOpentab(1);
      }
   },[parfor])  

   const myRef = useRef(null)

    const [metaData, setMetaData] = useState({
      url: `${APP_URL}${Links.SERVICES}`,
      title:  `Services - ${APP_NAME}`,
      description:'Services'
   })

    
   useEffect(() => {
      getServiceDetail(slug)
      /*setTimeout(() => {
         window.scrollTo({
           top: 0,
           left: 0,
           behavior: "smooth"
         });
       }, 10);
       */
   },[slug])



   const getServiceDetail = (slug) => {
      //console.log(user_id)

      fetch(`${API_ENDPOINT}/get_service_slug_front/${slug}`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json'
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('products======',result.data)
         if(result.status === 1){
            setService(result.data)
            setProcess(true);

            setMetaData({
              url: `${APP_URL}${Links.CATEGORY}`,
              title:  `Services ${(result.data.meta_title ? result.data.meta_title : result.data.title)} - ${APP_NAME}`,
              description:'Services'
           })

            //setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.title)
            //setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.description)
         }else{
            //navigate(Links.HOME);
         }
      })
   }



 
   return(
      <ScrollPageTop>
      <Seo 
         title={metaData.title}
         desc={metaData.description}
         url={`${APP_URL}${Links.SERVICES}/${slug}`}
         image={`./img/home-bg.jpg`}
      />
      <Header />
            {
            process
            ?
         <div className="mx-auto w-full flex-col py-5 px-5 md:flex-row xl:py-14 xl:px-8 2xl:px-14">
         <h1 class="mb-7 font-body text-xl font-bold text-heading md:text-2xl text-center">Service - {service?.title}</h1>
          {/*<nav class="flex" aria-label="Breadcrumb">
           <ol class="inline-flex items-center space-x-1 md:space-x-3">
             <li class="inline-flex items-center">
               <a href="#" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                 <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                 Home
               </a>
             </li>
             <li>
               <div class="flex items-center">
                 <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                 <a href="#" class="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Projects</a>
               </div>
             </li>
             <li aria-current="page">
               <div class="flex items-center">
                 <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                 <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Flowbite</span>
               </div>
             </li>
           </ol>
         </nav>*/}

         <div className="w-full max-w-xl mx-auto sm:px-0 border border-accent rounded overflow-hidden drop-shadow-lg">

          <Tab.Group defaultIndex={opentab} selectedIndex={opentab} onChange={setOpentab}>
            <Tab.List className="flex space-x-1 border-b border-accent">
              <Tab className={({ selected }) =>
                classNames(
                  'w-full text-md leading-5 py-4',
                  'focus:outline-none',
                  selected
                    ? 'bg-accent text-light'
                    : 'hover:bg-white/[0.12]'
                )
              }
              >Service description</Tab>
              <Tab className={({ selected }) =>
                classNames(
                  'w-full text-md leading-5 py-4',
                  'focus:outline-none',
                  selected
                    ? 'bg-accent text-light'
                    : 'text-accent-100 hover:bg-white/[0.12]'
                )
              } >Request Information</Tab>
              
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className={classNames(
                'bg-white p-5',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
              )}><div dangerouslySetInnerHTML={{__html:service?.description}} /></Tab.Panel>
              <Tab.Panel className={classNames(
                'bg-white',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
              )}><ServiceRequest service={service} /></Tab.Panel>
            
            </Tab.Panels>
          </Tab.Group>
      </div>
      </div>
       :
         <div className="w-full h-96 flex items-center justify-center">
            <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
         </div>
      }
      <Footer />
      </ScrollPageTop>
   )
}

export default ServiceDetails