import React, {useState, useEffect, useRef} from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Collapse from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import ScrollPageTop from '../../routes/ScrollPageTop'

import { Link , useParams, useNavigate, createSearchParams } from "react-router-dom";
import {API_ENDPOINT, APP_URL, APP_NAME, IMG_URL, LIMIT , LIMIT_PARTS} from '../config/Common'
import {Bed, Table, Sofa, Chair, Bucket, XrayIcon, MriIcon, CtScanIcon, Loading} from "../../custom-icons";
import { BiRupee } from "react-icons/bi";
import { motion as ms } from 'framer-motion';
import motion from '../../_utils/motionUtil.ts'
import { BiDownArrow } from "react-icons/bi";
import classNames from 'classnames';

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import PartsCard from '../../cards/PartsCard'
import {Links} from '../../routes/PathLink'
import { useSearch } from '../../ui/search/search.context.tsx';
import InfiniteScroll from "react-infinite-scroll-component";
import NoRecords from '../NoRecords'
import Seo from '../seo/seo'
import {isMobile} from "react-device-detect"
import { Range, getTrackBackground } from 'react-range';
import {CloseIcon} from '../../custom-icons'
import Button from '../../ui/Button.tsx'
import CatlougeSubCard from "../../cards/CatlougeSubCard";
import ModelSearch from './modelsearch';


const STEP = 100;
const MIN = 1000;
const MAX = 500000;
const rtl = false

const ModelDetails = () => {
   const [process, setProcess] = useState(false)
   const [products, setProducts] = useState(null)
   const [totalRec, setTotalRec] = useState(0)
   const [page, setPage] = useState(1)
   const [hasMore, setHasMore] = useState(true);
   const [searchvalue, setSerachValue] = useState(null);

   const {slug} = useParams()

   const myRef = useRef(null)

    const [metaData, setMetaData] = useState({
      url: `${APP_URL}${Links.SERVICES}`,
      title:  `Parts Lookup by Model Dixiechopper  - ${APP_NAME}`,
      description:'Parts Lookup by Model Dixiechopper'
   })

    useEffect(() => {
         getPartschild(1)
   },[slug,searchvalue]);



    const getPartschild = (pg) => {

      let paginate = `page=${pg}&limit=${LIMIT_PARTS}`;
       let serach = '';
       console.log("SERACH VL",searchvalue);
      if(searchvalue != '' && searchvalue != null){
          serach = `&search=${searchvalue}`;
      }
      fetch(`${API_ENDPOINT}/get_subpartcat_list/${slug}?${paginate}${serach}`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('cat products======',result)
         //setProducts(result.data)
         if(pg === 1){
            setProducts(result.data)
         }else{
            setProducts([...products, ...result.data])
         }
         let nextPage = page + 1;
         setPage(nextPage)
         setTotalRec(result.total)

         if(result.data.length < LIMIT_PARTS){
            setHasMore(false)
         }else{
            setHasMore(true)
         }

         setMetaData({
            url: `${APP_URL}${Links.CATEGORY}`,
            title:  `Parts Lookup by Model Dixiechopper - ${APP_NAME}`,
            description:'Parts Lookup by Model Dixiechopper'
         })
         
         if(isMobile){
            myRef?.current?.scrollIntoView( { behavior: 'smooth', block: 'start' } )
         }
         setProcess(true)
         
         /* let p = document.getElementById('product-list')
         p.scrollIntoView( { behavior: 'smooth', block: 'start' } ); */
      })
   }

   const fetchData = () => {
      console.log('page==',page)
      getPartschild(page);
   }

   return(
      <ScrollPageTop>
      <Seo 
         title={metaData.title}
         desc={metaData.description}
         url={metaData.url}
         image={`./img/home-bg.jpg`}
      />
      <Header />
      
      <div className="mx-auto flex w-full flex-col  py-10 px-5  xl:py-14 xl:px-8 2xl:px-14">
         <h1 class="mb-7 font-body text-xl font-bold text-heading md:text-2xl">Dixie Chopper Parts Lookup</h1>
            <nav class="flex" aria-label="Breadcrumb">
           <ol class="inline-flex items-center space-x-1 md:space-x-3">
             <li class="inline-flex items-center">
               <Link to="/partslookup" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-accent dark:text-gray-400 dark:hover:text-white">
                 <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                 Dixie Chopper
               </Link>
             </li>
              <li class="inline-flex items-center">
                <svg class="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
              </svg>
               <Link to="#" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-accent dark:text-gray-400 dark:hover:text-white">
                 {slug}
               </Link>
             </li>
           </ol>
         </nav>

         
          {/*<ModelSearch
                        variant={'minimal'}
                        className="mt-3 lg:mt-0"
                        setSerachValue={setSerachValue}
                        label="Search" placeholder="Search Model in current catlouge"  /> */}
            <main ref={myRef} id="product-list" className="block w-full lg:mt-6 xl:overflow-hidden ltr:xl:pl-0 ltr:xl:pr-5 rtl:xl:pr-0 rtl:xl:pl-5">
            {
            process
            ?
               <div name="grid "  className="px-4 xl:px-0">
                  <div className="w-full pb-6">
                  {
                  products  ?
                  <InfiniteScroll
                     dataLength={products.length} //This is important field to render the next data
                     next={fetchData}
                     hasMore={hasMore}
                     loader={
                        <h4 className="h-20 mt-5"><Loading className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />Loading...</h4>
                     }
                     scrollThreshold={0.7}
                     >
                     <div layout className="grid grid-cols-[repeat(auto-fill,minmax(180px,1fr))] gap-10">
                     {
                        products && products.map((item, key) => (
                           <CatlougeSubCard item={item} key={`catmodel-${item.part_cat_id}`} />
                        ))
                     }                     
                     </div>      
                  </InfiniteScroll>               
                  :
                     <NoRecords message="Sorry, No Info Found :(" />
                  
                  }
                     {/* <div className="mt-8 flex justify-center lg:mt-12"><button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 h-11 text-sm font-semibold md:text-base">Load More</button></div> */}
                  </div>
               </div>
               :
                  <div className="w-full h-96 flex items-center justify-center">
                     <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
                  </div>
               }
            </main>
      </div>
      <Footer />
      </ScrollPageTop>
   )
}

export default ModelDetails