import React ,{useState} from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const RECAPTCHA_EVENT_LIST = ["mousemove", "mousedown", "keydown", "touchstart"]

export const GoogleRecaptchaWrapper = props => {
  const { children } = props
  const [recaptchaProps] = useState({ defer: true })
  const [recaptchaNeeded, setRecaptchaNeeded] = useState(false)

  const initRecaptcha = e => {
    RECAPTCHA_EVENT_LIST.forEach(ev =>
      document.removeEventListener(ev, initRecaptcha)
    )
    setRecaptchaNeeded(true)
  }
  RECAPTCHA_EVENT_LIST.forEach(ev =>
      document.addEventListener(ev, initRecaptcha)
    )
  

  /*ifBrowser(() => {
    
  })
  */

  const wrapperProps = recaptchaNeeded
    ? {
        reCaptchaKey: "6Led2F0lAAAAAEF1-cjehalCzusmcf5GHmr3LWPC",
        scriptProps: recaptchaProps
      }
    : {}

  return (
    <GoogleReCaptchaProvider {...wrapperProps}>
      {children}
    </GoogleReCaptchaProvider>
  )
}