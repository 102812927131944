import React, {Fragment, useState, useEffect} from "react";
import Logo from './logo';
import { Link } from "react-router-dom";
import MobileNav from './mobile-nav';
import { useNavigate } from "react-router-dom";

import { Drawer } from "flowbite";
import {SearchIcon, CartOutlinedIcon, NavbarIcon, CloseIcon} from "../custom-icons";

import {APP_URL, API_ENDPOINT} from '../components/config/Common'
import { BsFillTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";

import {admin_phone} from '../components/config/Common'
import {Links} from '../routes/PathLink'
import ModalView from '../_utils/ModalView'
import Search from '../ui/search/search.tsx'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import Banner from '../components/banner/banner';
import { Menu, Transition } from '@headlessui/react'
import './header.css'
import { motion } from 'framer-motion';
import classNames from 'classnames';
import { useSearch } from '../ui/search/search.context.tsx'

//import { BiPhone, BiCalendar } from "react-icons/bi";

const Header = (props) => {
    const [openLogin, setOpenLogin] = useState(false)
    const [isLogin, setIsLogin] = useState(false)
    const [drawerVisible, setDrawerVisible] = useState(false)
    
    const [openLoginNav, setOpenLoginNav] = useState(false)
    const [callNowModal, setCallNowModal] = useState(false)
    const [hoverMenu, setHoverMenu] = useState(false);
    const [hoverrentMenu, setHoverRentMenu] = useState(false)

    const [category, setCategory] = useState(null)
    const [categoryrent, setCategoryRent] = useState(null)
    const [showSearch, setShowSearch] = useState(false)

    const { searchTerm } = useSearch();

    const navigate = useNavigate();

    useEffect(() => {
        //console.log('props===========',props)
        const access_token = localStorage.getItem('access_token');
        if(access_token){
            setIsLogin(true)
        }
        getAllCategory()
        getRentCategory();
        if(searchTerm){
            //setShowSearch(true)
        }
    }, [])

    const getAllCategory = () => {
        fetch(`${API_ENDPOINT}/get_sale_category`,{
              method: 'GET',
              headers : {        
                  'Content-Type': 'application/json',
                  'Accept': 'application/json', 
              }
          }).then(function (response) {
                  return response.json();
          }).then( (result)=> {
              console.log('categories======',result)
              setCategory(result.data)
              
          })
     }


    const getRentCategory = () => {
        fetch(`${API_ENDPOINT}/get_rent_category`,{
              method: 'GET',
              headers : {        
                  'Content-Type': 'application/json',
                  'Accept': 'application/json', 
              }
          }).then(function (response) {
                  return response.json();
          }).then( (result)=> {
              console.log('categories======',result)
              setCategoryRent(result.data)
              
          })
     }

    const openLoginModal = () => {        
        setOpenLogin(!openLogin)
        setOpenLoginNav(false)
    }
    const setLogin = () => {
        setIsLogin(true)
        openLoginModal()
        //console.log('paren===========')
        if(props.rerenderParentCallback){
            props.rerenderParentCallback();
        }
    }
   
    const cart_count = (count) => {
        //setCartCount(count)
    }

    const openDrawer = () => {
        const options = {
            placement: 'right',
            backdrop: true,
            bodyScrolling: false,
            edge: false,
            edgeOffset: '',
            backdropClasses: 'cart-drawer bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30',
            onHide: () => {
                //console.log('drawer is hidden');
                setDrawerVisible(false)
            },
            onShow: () => {
                //console.log('drawer is shown');
                setDrawerVisible(true)
            },
            onToggle: () => {
                //console.log('drawer has been toggled');
            }
          };
        const $targetEl = document.getElementById('mobile-menu-left-drawer');
        const drawer: DrawerInterface = new Drawer($targetEl, options);
        drawer.show();
    }
    
    const closeDrawer = () => {
        //console.log('close drawer')
        const options1 = {
            placement: 'right',
            backdrop: false,
            bodyScrolling: true,
            edge: false,
            edgeOffset: '',
            backdropClasses: 'bg-gray-900 bg-opacity-0 dark:bg-opacity-0 fixed',
          };
        const $targetEl = document.getElementById('mobile-menu-left-drawer');
        const drawer: DrawerInterface = new Drawer($targetEl, options1);
        drawer.hide();
        let cartDrawer = document.querySelector(".cart-drawer");
        let body = document.querySelector("body");
        cartDrawer.remove();
        body.classList.remove("overflow-hidden");
    }

    
    const closeLoginNav = () => {
        setOpenLoginNav(false)
        setCallNowModal(false)
    }

    const openLoginNavModal = () => {
        setOpenLoginNav(true)
    }
    const openCallNowModal = () => {
        setCallNowModal(true)
    }

    const toggleSearch = () => {
        setShowSearch(!showSearch)
    }
    
    return (
        <>
        <header className={'site-header-with-search'}>
            <div className="h-8 bg-[#515151] flex items-center justify-end px-6 relative z-30">
                <a href="mailto:powertripohio@gmail.com" className="pr-4 text-sm text-light flex items-center"><GrMail className="mr-2" /> powertripohio@gmail.com</a>
                <a href="tel:+1 614 642 1030" className="text-sm text-light flex items-center"><BsFillTelephoneFill className="mr-2" /> 614-642-1030</a>
            </div>
            <div className="flex items-center justify-between border-b border-[#3c3c3c] bg-dark shadow-sm transition-transform duration-300 dark:bg-gray-800 dark:border-gray-600 relative z-30">
            <div
                className={'relative z-30 flex w-full transform-gpu items-center lg:justify-start justify-between '}
             >
                <div className="flex items-center lg:w-auto relative m-head__logo h-20 lg:pl-7">
                    <Logo className={'ltr:ml-0 rtl:mr-0'} />
                </div>
                <ul className="lg:hidden shrink-0 items-center flex h-20">
                    <li className="c-nav-main__item pl-6 flex items-center">
                    <span className="cursor-pointer pl-3 text-zinc-300" onClick={toggleSearch}>
                    {
                    !showSearch 
                    ?
                    <SearchIcon className="h-6 w-6" />
                    :
                    <CloseIcon className="h-6 w-6" />
                    }
                </span>
                    </li>
                    <li className="c-nav-main__item px-6">
                        <div className="flex items-center font-normal text-heading no-underline transition duration-200 text-zinc-300 hover:text-accent-300 focus:text-accent-300 dark:text-zinc-400 font-oswald text-xl pl-5 cursor-pointer" onClick={openDrawer}
                        ><NavbarIcon /></div>
                    </li>
                </ul>
                
                <ul className="hidden shrink-0 items-center lg:flex h-20">
                    <li className="c-nav-main__item px-8">
                        <Link
                            to={Links.PARTSLOOKUP}
                            className="flex items-center font-normal text-heading no-underline transition duration-200 text-zinc-300 hover:text-accent-300 focus:text-accent-300 dark:text-zinc-400 font-oswald text-xl"
                        >Parts Lookup </Link>
                    </li>
                    <li className="relative c-nav-main__item px-8" 
                        onMouseOver={() => setHoverMenu(true)}
                        onMouseLeave={() => setHoverMenu(false)}
                    >
                        <Link
                            to={Links.CATEGORY}
                            className="flex items-center font-normal text-heading no-underline transition duration-200 text-zinc-300 hover:text-accent-300 focus:text-accent-300 dark:text-zinc-400 font-oswald text-xl"
                        >Equipment For Sale</Link>
                        
                        {category &&
                        <Menu as="div">
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                            show={hoverMenu}
                        >
                            <Menu.Items
                                as="ul"
                                className="absolute -right-10 mt-20 w-56 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-600"
                            >
                                {
                                category.map((val, key) => (
                                <Menu.Item>
                                    <Link to={`/equipment-for-sale/${val.slug}`} className='block px-4 py-3 text-sm dark:text-zinc-300 hover:bg-accent hover:text-white'>{val.cat_name}</Link>
                                </Menu.Item>
                                ))
                                }
                                
                            </Menu.Items>
                        </Transition>
                        </Menu>
                        }
                    </li>
                    <li className="relative c-nav-main__item px-8" 
                        onMouseOver={() => setHoverRentMenu(true)}
                        onMouseLeave={() => setHoverRentMenu(false)}
                    >
                        <Link
                            to={Links.EQUIPMENTFORRENT}
                            className="flex items-center font-normal text-heading no-underline transition duration-200 text-zinc-300 hover:text-accent-300 focus:text-accent-300 dark:text-zinc-400 font-oswald text-xl"
                        >Equipment For Rent</Link>
                        
                        {categoryrent &&
                        <Menu as="div">
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                            show={hoverrentMenu}
                        >
                            <Menu.Items
                                as="ul"
                                className="absolute -right-10 mt-20 w-56 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-600"
                            >
                                {
                                categoryrent.map((val, key) => (
                                <Menu.Item>
                                    <Link to={`/equipment-for-rent/${val.slug}`} className='block px-4 py-3 text-sm dark:text-zinc-300 hover:bg-accent hover:text-white'>{val.cat_name}</Link>
                                </Menu.Item>
                                ))
                                }
                                
                            </Menu.Items>
                        </Transition>
                        </Menu>
                        }
                    </li>
                      <li className="c-nav-main__item px-8">
                        <Link
                            to={Links.FINANCE}
                            className="flex items-center font-normal text-heading no-underline transition duration-200 text-zinc-300 hover:text-accent-300 focus:text-accent-300 dark:text-zinc-400 font-oswald text-xl"
                        >Finance</Link>
                    </li>
                    <li className="c-nav-main__item px-8">
                        <Link
                            to={Links.SERVICES}
                            className="flex items-center font-normal text-heading no-underline transition duration-200 text-zinc-300 hover:text-accent-300 focus:text-accent-300 dark:text-zinc-400 font-oswald text-xl"
                        >Services</Link>
                    </li>
                    {/*<li className="c-nav-main__item px-8">
                        <Link
                            to={Links.CONTACT}
                            className="flex items-center font-normal text-heading no-underline transition duration-200 text-zinc-300 hover:text-accent-300 focus:text-accent-300 dark:text-zinc-400 font-oswald text-xl"
                        >Contact</Link>
                    </li> */}
                </ul>
            </div>
            <div className="hidden lg:block px-6">
                <span className="cursor-pointer text-zinc-300" onClick={toggleSearch}>
                    {
                    !showSearch 
                    ?
                    <SearchIcon className="h-6 w-6" />
                    :
                    <CloseIcon className="h-6 w-6" />
                    }
                </span>
            </div>
            </div>
            { showSearch && 
            <div className={classNames("shadow-lg absolute z-20 px-6 w-full bg-dark animate__animated",{"animate__slideInDown": showSearch, 
            "animate__bounceOutUp": !showSearch} )}><Search variant={'with-shadow'} /> </div>
            }    
        </header>
        
        <MobileNav openLogin={openLoginNavModal} drawerVisible={openDrawer} />

        </>
    )
}


export default Header;