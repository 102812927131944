import React, {useState, useEffect, useRef} from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Collapse from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import ScrollPageTop from '../../routes/ScrollPageTop'

import { Link , useParams, useNavigate, createSearchParams } from "react-router-dom";
import {API_ENDPOINT, APP_URL, APP_NAME, IMG_URL, LIMIT} from '../config/Common'
import {Bed, Table, Sofa, Chair, Bucket, XrayIcon, MriIcon, CtScanIcon, Loading} from "../../custom-icons";
import { BiRupee } from "react-icons/bi";
import { motion as ms } from 'framer-motion';
import motion from '../../_utils/motionUtil.ts'
import { BiDownArrow } from "react-icons/bi";
import classNames from 'classnames';

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import ServiceCard from '../../cards/ServiceCard'
import {Links} from '../../routes/PathLink'
import { useSearch } from '../../ui/search/search.context.tsx';
import InfiniteScroll from "react-infinite-scroll-component";
import NoRecords from '../NoRecords'
import Seo from '../seo/seo'
import {isMobile} from "react-device-detect"
import { Range, getTrackBackground } from 'react-range';
import {CloseIcon} from '../../custom-icons'
import Button from '../../ui/Button.tsx'
import { Tab } from '@headlessui/react'
import CatlougeCard from "../../cards/CatlougeCard";

const STEP = 100;
const MIN = 1000;
const MAX = 500000;
const rtl = false

const PartsLookup = () => {
   const [process, setProcess] = useState(false)
   const [partcatlouge, setPartcatlouge] = useState(null)
   const [totalRec, setTotalRec] = useState(0)
   const [page, setPage] = useState(1)
   const [hasMore, setHasMore] = useState(true)

   const myRef = useRef(null)

    const [metaData, setMetaData] = useState({
      url: `${APP_URL}${Links.SERVICES}`,
      title:  `Parts Look up - ${APP_NAME}`,
      description:'Parts Look up'
   })

    useEffect(() => {
         getPartscatlogue()
   },[])

    const getPartscatlogue = () => {

      //let paginate = `page=${pg}&limit=${LIMIT}`;
     
      fetch(`${API_ENDPOINT}/parts_catlouge`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('cat products======',result)
         console.log('cat products data======',result.data)

         setPartcatlouge(result.data);

       
         setMetaData({
            url: `${APP_URL}${Links.PARTSLOOKUP}`,
            title:  `Parts Look up - ${APP_NAME}`,
            description:'Parts Look up'
         })
         
         if(isMobile){
            myRef?.current?.scrollIntoView( { behavior: 'smooth', block: 'start' } )
         }
         setProcess(true)
         
         /* let p = document.getElementById('product-list')
         p.scrollIntoView( { behavior: 'smooth', block: 'start' } ); */
      })
   }

   

   return(
      <ScrollPageTop>
      <Seo 
         title={metaData.title}
         desc={metaData.description}
         url={metaData.url}
         image={`./img/home-bg.jpg`}
      />
      <Header />
      
      <div className="mx-auto flex w-full flex-col  py-10 px-5  xl:py-14 xl:px-8 2xl:px-14">
          <h1 class="mb-7 font-body text-xl font-bold text-heading md:text-2xl">Dixie Chopper Parts Lookup</h1>
            <nav class="flex" aria-label="Breadcrumb">
           <ol class="inline-flex items-center space-x-1 md:space-x-3">
             <li class="inline-flex items-center">
               <Link to="/partslookup" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-accent dark:text-gray-400 dark:hover:text-white">
                 <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                 Dixie Chopper
               </Link>
             </li>
           </ol>
         </nav>
            <main ref={myRef} id="product-list" className="relative block w-full lg:mt-6 xl:overflow-hidden ltr:xl:pl-0 ltr:xl:pr-5 rtl:xl:pr-0 rtl:xl:pl-5">
                <div name="grid "  className="px-4 xl:px-0">
                  <div className="w-full pb-6">
                      <div layout className="grid grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-10">
                        {partcatlouge && Object.entries(partcatlouge).map(([key, value], index) => (
                              <><CatlougeCard item={value} key={`catlouge-${value.part_cat_id}`} /></>
                           ))
                        }  
                       </div>
                  </div>
               </div>  

              {/*} <Tab.Group vertical  >
               <Tab.List className="flex flex-col content-start  space-x-1 rounded-xl  p-1">
                {
                     partcatlouge && Object.entries(partcatlouge).map(([key, value], index) => (
                        <Tab className="px-4 text-left bg-white hover:bg-gray-300/20 py-3 border-2">{value.name}</Tab>
                     ))
                  }        
               </Tab.List>
               <Tab.Panels >
                {
                     partcatlouge && Object.entries(partcatlouge).map(([key, value], index) =>{ 
                        return (
                        <Tab.Panel className="absolute top-0 left-1/4  bg-gray-300 w-full h-full overflow-y-auto ">
                        <>
                        {value.child ?
                        (
                           <>
                            <Tab.Group vertical  onChange={(index) => {
                                console.log('Changed selected tab to:', index)
                              }}  >
                              <Tab.List className="flex flex-col content-start  ">
                              {value.child.map((itm) =>{
                                 if(itm?.child){
                                    return(<Tab className="px-4 text-left bg-white py-3 hover:bg-gray-300/20 py-3 border-2">{itm.name}</Tab>)
                                 }else{
                                  //return(<Tab className="px-4 text-left bg-white hover:bg-gray-300/20 py-3 border-0"><Link to={`/partslookup/model/${itm.slug}`}><div className="px-4 text-left bg-white py-3 hover:bg-gray-300/20 py-3 border-2">{itm.name}</div></Link></Tab>)

                                 }
                              })}
                               </Tab.List>
                              <Tab.Panels>
                              {value.child.map((itm) =>{
                              if(itm?.child){
                                return(
                                 
                                    <Tab.Panel className="absolute top-0 left-2/4  bg-gray-300 w-full h-full  ">
                                    <>
                                    {itm.child.map((itmN) =>{
                                       return(<Link to={`/partslookup/model/${itmN.slug}`}><div className="px-4 text-left bg-white py-3 hover:bg-gray-300 py-3 border-2">{itmN.name}</div></Link>)
                                       //return(null);
                                    })
                                    }
                                    </>
                                    </Tab.Panel>
                                   
                                 )
                              }else{
                                 return(<Link to={`/partslookup/model/${itm.slug}`}><div className="px-4 text-left bg-white py-3 hover:bg-gray-300/20 py-3 border-2">{itm.name}</div></Link>)
                              }
                             
                           })
                           }
                           </Tab.Panels>
                           </Tab.Group>
                           </>
                        )
                           :
                        (<div></div>)
                        }
                        </>
                        </Tab.Panel>
                     )
                     }
                  )
                  }
   
               </Tab.Panels>
             </Tab.Group> */}
            </main>
      </div>
      <Footer />
      </ScrollPageTop>
   )
}

export default PartsLookup