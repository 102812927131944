import React from "react";
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import {Links} from '../../routes/PathLink'

const Seo = (props) => {

   const {title,url,desc, image} = props

   const siteMetadata = {
      url: `${url}`,
      title:  `${title}`,
      description: `${desc}`,
      image: `${image}`,
   }

   return(
      <ReactSEOMetaTags
         render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
         website={{ ...siteMetadata }}
      />
   )
}

export default Seo